.ui-contact-complete {
  padding-bottom: 100px;

  &__icon {
    text-align: center;
  }

  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    color: #333333;
  }

  &__description {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    color: #666666;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;

    @media screen and (min-width: 992px) {
      min-width: 100%;
      padding-bottom: 16px;
    }
  }

  &__time-overview {
    padding-top: 100px;
  }

  .ant-row {
    .ant-col:nth-child(1).col-content {
      padding-bottom: 0px !important;
    }
    .ant-col:nth-child(2) {
      padding-top: 0px !important;
    }
  }

  &__action {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 10px 15px;
    border-top: solid 1px #d9dbe9;
    background-color: #ffffff;
  }
}
