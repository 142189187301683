@import "src/styles/variables.scss";

.bottom-bar-container {
  background: #fff;
  z-index: 100;
  height: 60px;
  box-shadow: 0px -2px 16px rgba(16, 24, 40, 0.05),
    0px 0px 6px rgba(16, 24, 40, 0.03);
  border-radius: 16px 16px 0px 0px;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .item-bar.active {
    .title {
      color: $hrsk-primary-color;
      font-weight: 700;
    }
  }
  .item-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 11px;
    cursor: pointer;
    .title {
      color: #a3a2a2;
    }

    &__notice {
      position: absolute;
      top: -5px;
      right: -2px;
      height: 18px;
      width: 18px;
      color: #ffffff;
      background-color: #f04438;
      font-weight: 700;
      font-size: 11px;
      border-radius: 8px;
      text-align: center;
      display: flex;
      align-items: center;
      span {
        margin: auto;
      }
    }

    .relative {
      position: relative;
    }

    .font-9 {
      font-size: 9px !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .bottom-bar-container {
    height: 75px;
  }
}
