.ui-footer {
  border-top: 1px solid #d9dbe9;
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eff0f1;
  .color-title {
    color: #333 !important;
  }
  .footer-body {
    max-width: 1334px;
    position: relative;
    width: calc(100% - 16px);
    .ant-col {
        padding: 0 24px;
    }
    .text-body-4,  .text-body-3 {
        line-height: 30px !important;
    }
    .image-icon-youtube {
        width: 100%;
        padding-top: 12px;
    }
    .img-store {
        padding-top: 12px;
        padding-bottom: 12px;
        display: flex;
        img {
            height: 40px;
            width: 128px !important;
        }
        img:last-of-type {
            margin-left: 8px;
        }
    }
  }

  .row-footer {
    .ant-col-7:first-of-type {
        padding-left: 0px;
    }
    .ant-col-7:last-of-type {
        padding-right: 0px;
    }
  }
}
