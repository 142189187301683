.tree-container {
  padding-bottom: 8px;
  .ant-custom-checkbox > span:nth-child(2) {
    padding: 0px !important;
  }
  .expand-icon {
    min-width: 10px;
    min-height: 10px;
    display: flex;
    align-items: center;
  }
  .ant-checkbox {
    margin-left: 12px !important;
  }
  .checkbox-group-view {
    display: flex;
    align-items: center;
    width: 100%;
    .ant-custom-checkbox > span:nth-child(2) {
      display: none;
    }
  }
  .tree-child {
    padding-left: 32px;
    padding-top: 8px;
    .ant-custom-checkbox {
      color: #666;
    }
    .tree-container:last-of-type {
      padding-bottom: 0px;
    }
  }

  .tree-normal {
    margin-left: 10px;
    display: flex;
    align-items: center;
    .text-checkbox {
      margin-left: 12px;
    }
    .ant-form label {
      // min-width: 60px;
    }
  }

  .ant-custom-checkbox {
    margin-left: auto;
  }

  .text-checkbox.selected {
    color: #FF671E;
  }
  .text-checkbox {
    flex-grow: 1;
  }

  .tree-parent {
    width: 100%;
    display: flex;
    align-items: center;
    .text-checkbox {
      margin-left: 12px;
    }
    .ant-custom-checkbox {
      margin-left: auto;
      width: max-content;
      // min-width: 60px;
    }
    label {
      width: 100%;
    }
  }

  .ant-checkbox-disabled + span {
    color: #333 !important;
  }
}
