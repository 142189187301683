.ui-contact-method {
  padding-bottom: 100px;

  &__note {
    margin-bottom: 12px;
    font-size: 15px;
  }

  &__action {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 10px 15px;
    border-top: solid 1px #d9dbe9;
    background-color: #ffffff;
    z-index: 99;
  }
}
