.ui-my-page-title-menu {
  &__content {
    border-bottom: solid 1px #d9dbe9;
    min-height: 106px;
  }

  &__title {
    display: flex;
    padding-top: 35px;
    &-icon {
      height: 19px;
      margin-right: 33px;
      cursor: pointer;
    }
    &-content {
      font-size: 24px;
      font-weight: 700;
      color: #333333;
      line-height: 18px;
    }
  }

  &__id {
    font-size: 15px;
    color: #333333;
    padding-bottom: 24px;
    font-weight: 700;
    padding-top: 4px;
  }
}
