.ui-my-page {
    display: block;
    height: 100%;
    &__content {
      padding: 0 16px;
    }
  
    &__name {
      font-size: 20px;
      font-weight: 700;
      color: #FF671E;
      padding-top: 24px;
      padding-bottom: 4px;
    }
  
    &__email {
      font-size: 14px;
      color: #666666;
      padding-bottom: 24px;
      border-bottom: solid 1px #D9DBE9;
    }
  
    &__menu {
      padding-top: 24px;
    }
  
    &__menu-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding-bottom: 24px;
    }
  
    &__menu-left {
      display: flex;
      align-items: center;
      flex-basis: 80%;
    }
  
    &__menu-label {
      color: #333333;
      font-size: 15px;
      font-weight: 700;
      padding-left: 24px;
    }
  
    &__arrow {
      flex-basis: 20%;
    }
  }
  