.ui-contact-time {
  padding-bottom: 100px;

  &__note {
    margin-bottom: 12px;
    font-size: 15px;
    color: #333333;
  }

  &__calculate-date {
    font-size: 15px;
    padding-top: 12px;
    padding-bottom: 24px;
  }

  &__action {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 10px 15px;
    border-top: solid 1px #d9dbe9;
    background-color: #ffffff;
  }

  &__seller-information {
    margin-bottom: 12px;
    word-break: break-all;
  }

  &__seller-company {
    margin-right: 12px;
  }

  &__seller-title {
    font-size: 15px;
    font-weight: 700;
    color: #333;
  }

  &__sellerCompanyName,
  &__sellerName {
    font-size: 15px;
    color: #333;
  }

  &__warning {
    color: #f04438;
  }
}
