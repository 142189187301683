.mobile-dashboard-container {
  display: block;
  position: relative;
  height: 100%;
  .outlet-container {
    padding-bottom: 60px;
    display: block;
    height: 100%;
    .outlet-container {
        // padding-bottom: 100px;
        display: block;
        height: 100%;
        box-sizing: border-box;
    }
    box-sizing: border-box;
  }
  @media screen and (min-width: 768px) {
    .outlet-container {
      padding-bottom: 74px;
    }
  }
}

.lg-dashboard-container {
  display: block;
  position: relative;
  width: 100%;
}
