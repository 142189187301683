@import "src/styles/variables.scss";

@media screen and (min-width: 992px) {
  .product-confirm-info {
    width: 750px;
    align-self: center;
    .row-info {
      display: flex;
      padding-bottom: 16px;
      border-bottom: 1px solid $hrsk-line-color;
      padding-top: 16px;
      &:nth-child(2) {
        .i-value {
          padding-right: 42px;
        }
      }
      &:nth-child(1) {
        .i-value {
         transform: none !important;
        }
      }
      .i-title {
        min-width: 204px !important;
      }
      .i-value { 
        padding-left: 16px;
        width: 250px !important;
        text-align: right !important;
        // padding-left: 36px;
      }
    }
    .v-list-slider-item {
      .app-image-container {
        width: 215px;
        height: 108px;
      }
    }
  }
}

.product-confirm-info {
  .confirm-caption {
    padding: 16px 16px 0 16px;
  }

  .product-order-detail {
    padding: 0 16px;
  }
  .order-price {
    margin-top: 16px;
    margin-bottom: 8px;
    padding: 16px;
    border: 1px solid $hrsk-line-color;
    border-radius: 8px;
    .color-title {
      font-weight: 400;
    }
    .row-info:first-of-type {
      padding-top: 0px;
    }
    .row-info:last-of-type {
      border-bottom: none;
      padding-bottom: 0px;
    }
    .row-info {
      display: flex;
      padding-bottom: 16px;
      border-bottom: 1px solid $hrsk-line-color;
      padding-top: 16px;
      .i-title {
        min-width: 128px;
        text-align: left;

        @media screen and (min-width: 768px) {
          text-align: right;
        }
      }
      .i-value {
        //width: max-content;
        //flex-grow: 1;
        width: 150px;
        overflow: hidden;
        flex-wrap: nowrap;
        word-break: break-all;
        text-align: right;
      }

      &:nth-child(1) {
        .i-value {
          transform: translateX(45px);

          @media screen and (max-width: 768px) {
            transform: translateX(-27px);
          }
        }
      }

      &:nth-child(2) {
        .i-value {
          @media screen and (max-width: 768px) {
            transform: translateX(-70px);
          }
        }
      }
    }
  }

  .button-container {
    bottom: 0px;
    width: 100%;
    padding: 16px 16px 0 16px;
    position: fixed;
    height: 90px;
    border-top: 1px solid $hrsk-line-color;
    background: #fff;
    .button-secondary {
      width: 100%;
    }
  }
}

.product-skeleton-avatar {
  width: 100% !important;
  height: 560px;

  span {
    width: 100% !important;
    height: 100% !important;
  }

  @media screen and (max-width: 991px) {
    height: 250px !important;
  }
}

.product-skeleton-slider {
  width: 100% !important;
  height: 80px;

  &.small {
    height: 40px;
  }

  span {
    width: 100% !important;
    height: 100% !important;
  }
}

.skeleton-product {
  padding: 8px;
  .ant-skeleton-avatar {
    border-radius: 4px;
  }
}
