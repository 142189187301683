// .list-slider-container-lg {
//     width: 100%;
//     margin-top: 16px;
//     width: 140vw !important;
//     .list-container {
//       display: flex !important;
//       align-items: center;
//       .list-slider-item:first-of-type {
//         min-width: 70vw !important;
//         margin-right: 16px;
//       }
//     }

.list-slider-item-lg {
  // width: 80vw !important;
  margin-right: 24px;
  position: relative;

  .text-action-search {
    margin-left: auto;
    padding: 4px 8px;
    border-radius: 4px;
    float: right;
  }
  
  .lg-button-action {
    margin-top: 12px;
  }

  .app-image-container {
    margin: 1px;
    height: 130px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    // filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
    //   drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));
  }
  .product-price {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .button-like.disabled {
    pointer-events: none;
  }

  .button-like {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 8px;
    top: 8px;
    background: rgba(233, 245, 255, 0.8);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .product-title {
    margin-top: 8px;
    width: 100%;
  }

  .product-des {
    margin-top: 4px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    .dot-break {
      margin: 0px 8px;
    }
  }

  .product-action {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
    display: flex;
    align-items: center;
    .text-value {
      margin-left: 8px;
      margin-right: 16px;
    }
    .action {
      margin-left: auto;
      cursor: pointer;
      color: #333;
      font-weight: bold;
      font-size: 14px;


    }
  }
}
//   }
