.app-video-container {
  position: relative;
  .icon-play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .image-preload {
    position: absolute;
    left: 0;
    top: 0;
  }
}
