@import "src/styles/variables.scss";
// @import "~antd/dist/antd.css";
@import "src/styles/mixins.scss";

/*override css*/

.ant-dropdown {
  z-index: 1060;
}

.ant-modal-mask {
  z-index: 2000;
}

.ant-modal-wrap {
  z-index: 3000;
}

//popover
.ant-popover-content {
  width: 170px;
}

.ant-popover-inner {
  border-radius: 8px;
}

.ant-popover-inner-content {
  border-radius: 8px;
  background: #fcfcfc;
  border: 0.5px solid #d9dbe9;
  box-shadow: 0px -2px 16px rgba(16, 24, 40, 0.05),
    0px 0px 6px rgba(16, 24, 40, 0.03);
}

.ant-popover-arrow {
  display: none;
}
//input number
.number-custom-app-text-field {
  input {
    text-align: right;
    padding-right: 8px !important;
    margin: 8px;
    margin-right: 0px;
  }
  padding: 4px 8px;
  border-color: #cacaca;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.app-input-number,
.app-text-field {
  padding: 4px 8px;
  border-color: #cacaca;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.ant-form-item-control-custom {
  margin-bottom: 0px;
  .ant-form-item-control {
    min-height: 72px;
  }
  .ant-form-item-extra {
    font-size: 14px !important;
    font-weight: 700;
    color: #333333 !important;
    position: absolute;
    bottom: 34px;
    min-width: 120px;
    transition: none !important;
    transform: none !important;
    margin-left: 16px;
  }

  .number-custom-app-text-field {
    margin-left: auto;
    width: calc(100% - 120px);
  }
}

.app-input-number .ant-input-number-input {
  padding: 0 !important;
  width: 100%;
}

.app-input-number {
  .ant-input-number-input-wrap {
    width: 100%;
  }
}

.app-input-number:hover {
  border-color: #ffb74d !important;
  @include boxShadow;
}

.app-input-number.ant-input-number-focused {
  border-color: #ff671e !important;
  @include boxShadow;
}

.app-input-number .ant-input-number-handler-wrap {
  display: none;
}
/*text field*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-form-item-explain.ant-form-item-explain-connected > div:not(:first-child) {
  display: none;
}

.ant-form-item-control .ant-input.app-text-field {
  padding: 10px 14px;
  border-color: #cacaca !important;
  border-radius: 8px;
}

.ant-input.app-text-field.ant-input-status-error {
  border-color: #f04438 !important;
  @include boxShadow;
}

.ant-input.app-text-field:focus {
  border-color: #ff671e !important;
  @include boxShadow;
}

.ant-input.app-text-field.ant-input-status-error:focus {
  border-color: #f04438 !important;
  @include boxShadow;
}

.ant-input.app-text-field[disabled] {
  background: #eff0f1;
  @include boxShadow;
}

.ant-input.app-text-field:hover {
  border-color: #ffb74d !important;
  @include boxShadow;
}

.ant-form-item-control input[type="text"].app-text-field::placeholder {
  font-size: 15px;
  color: #cacaca;
}

.ant-form-item-label {
  font-weight: 700;
  color: #333333;
  font-size: 14px;
}

.ant-form-item-label .ant-form-item-required::before {
  position: absolute;
  left: 100%;
  margin-right: 0 !important;
  margin-left: 4px;
  color: #ff671e !important;
}

.ant-form-item-label .ant-form-item-required {
  position: relative;
}

.ant-form-item-explain-error {
  font-size: 12px !important;
  color: #f04438 !important;
}

//show only first error
//.ant-form-item-explain-error:not(:first-child) {
//  display: none;
//}

/*password*/
.ant-input-password.app-input-password {
  padding: 10px 14px;
  border-color: #cacaca !important;
  border-radius: 8px;
}

.ant-input-password.app-input-password .ant-input {
  padding: 0 !important;
}

.ant-input-password.app-input-password .ant-input:hover {
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper.ant-input-password.app-input-password:hover {
  border-color: #ffb74d !important;
  @include boxShadow;
}

.ant-input-affix-wrapper.ant-input-password.app-input-password.ant-input-affix-wrapper-status-error {
  border-color: #f04438 !important;
  @include boxShadow;
}

.ant-input-affix-wrapper.ant-input-password.app-input-password.ant-input-affix-wrapper-status-success {
  border-color: #ff671e;
  @include boxShadow;
}

//textarea
.app-custom-textarea .ant-form-item-explain-error {
  padding-top: 4px;
}

.app-textarea .ant-input {
  padding: 10px 14px;
  border-color: #cacaca !important;
  border-radius: 8px;
  &::after {
    position: absolute;
    right: 8px;
    bottom: 0px;
  }
}

.app-textarea .ant-input.ant-input-status-error {
  border-color: #f04438 !important;
  box-shadow: none !important;
}

.app-textarea .ant-input:focus {
  border-color: #ff671e !important;
  box-shadow: none !important;
}

.app-textarea .ant-input[disabled] {
  background: #eff0f1;
  box-shadow: none !important;
}

.app-textarea .ant-input:hover {
  border-color: transparent !important;
  // @include boxShadow;
  box-shadow: none !important;
}

.app-textarea .ant-input::placeholder {
  font-size: 15px;
  color: #cacaca;
}
.app-textarea {
  position: relative;
  border: 1px solid #cacaca !important;
  border-radius: 8px;
  padding-bottom: 24px;
  textarea {
    resize: none;
    border: none !important;
  }
}

.ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
  position: absolute;
  right: 8px;
  bottom: 22px;
}
/*select*/
.app-select .ant-select-selector {
  padding: 6px 14px !important;
  border-color: #cacaca !important;
  border-radius: 8px !important;
  height: auto !important;
  @include boxShadow;
}

.app-select.ant-select-show-arrow.ant-select-show-search input {
  margin-top: 6px !important;
}

.app-select .ant-select-selector:hover {
  border-color: #ffb74d !important;
  @include boxShadow;
}

.ant-select-custom-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #ffeacc !important;
}

.ant-select-custom-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffeacc !important;
}

.ant-select-custom-dropdown {
  border: solid 1px #cacaca !important;
  border-radius: 8px;
  box-shadow: none;
  padding: 0;
  overflow: hidden;

  div.ant-select-item {
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    div.ant-select-item {
      padding: 10px 12px;

      &:not(:last-child) {
        border-bottom: solid 1px #d9dbe9;
      }
    }
  }
}

.ant-select-custom-dropdown:hover {
  border-color: #ffb74d !important;
  @include boxShadow;
}

.app-select
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffeacc;
}

.app-select.app-year-select span.ant-select-clear {
  height: 100%;
  top: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  text-align: right;
  transform: translateX(-35px);
  opacity: 1 !important;

  @media screen and (max-width: 768px) {
    width: 50px;
  }
}

.app-select.contact-select span.ant-select-clear {
  height: 100%;
  top: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  text-align: right;
  transform: translateX(-20px);
  opacity: 1 !important;

  @media screen and (max-width: 768px) {
    width: 30px;
  }
}

/*button*/

.app-button.ant-btn {
  border-radius: 8px;
  box-shadow: none;
  text-shadow: none;
  font-size: 14px;
  font-weight: 700;
  height: auto;
  padding: 10px 14px;
  width: 100%;
  cursor: pointer;
}

.app-button.ant-btn[disabled] {
  background: #eff0f1 !important;
  color: #cacaca !important;
  border-color: #eff0f1 !important;
}

.app-button.ant-btn.ant-btn-custom-primary {
  background-color: #ff671e;
  border-color: #ff671e;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-button.ant-btn.ant-btn-custom-secondary {
  background-color: #fff;
  border-color: #ff671e;
  color: #ff671e;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*date-picker*/
.app-datepicker.ant-picker {
  padding: 10px 14px;
  border-radius: 8px;
  border-color: #cacaca !important;
  width: 100%;
  @include boxShadow;
}

.app-datepicker.ant-picker:hover {
  border-color: #ffb74d !important;
  @include boxShadow;
}

.app-datepicker.ant-picker-status-error {
  border-color: #f04438 !important;
  @include boxShadow;
}

.app-datepicker {
  .ant-calendar-input-wrap {
    display: none;
  }
}

.app-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 1px solid #ffb74d !important;
}

.app-picker-dropdown .ant-picker-cell-in-view .ant-picker-cell-inner:hover {
  background-color: #ffb74d !important;
}

.app-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background-color: #ff671e !important;
}

.app-picker-dropdown .ant-picker-footer a {
  color: #ff671e !important;
}

//.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
//  background-color: #FFEACC;
//}
//
//.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
//  border-color: #E65100;
//}

/*time-picker*/
.app-timepicker.ant-picker {
  padding: 10px 14px;
  border-radius: 8px;
  border-color: #cacaca !important;
  width: 100%;
  @include boxShadow;
}

.app-timepicker.ant-picker:hover {
  border-color: #ffb74d !important;
  @include boxShadow;
}

.app-timepicker.ant-picker-status-error {
  border-color: #f04438 !important;
  @include boxShadow;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background-color: #ff671e !important;
}

.ant-picker-time-panel-column .ant-picker-time-panel-cell-inner:hover {
  background-color: #ffb74d !important;
}

.ant-picker-footer .ant-picker-ranges .ant-picker-now a {
  color: #ff671e !important;
}

.ant-picker-footer .ant-picker-ranges .ant-picker-ok button:not([disabled]) {
  background-color: #ff671e !important;
  border-color: #ff671e !important;
}
/*modal*/
.app-modal .ant-modal-content {
  box-shadow: 0 4px 24px -4px rgba(16, 24, 40, 0.08),
    0 2px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
}

/*slider*/
.app-slider .ant-slider-track {
  background-color: #ff671e !important;
}

.app-slider .ant-slider-handle {
  background-color: #ff671e !important;
  border-color: #ff671e !important;
}

.app-slider .ant-slider-rail {
  background-color: #ffeacc !important;
}

/*checkbox*/
.ant-custom-checkbox {
  display: flex;
  // flex-direction: row-reverse;
}

.ant-custom-checkbox > span:nth-child(1) {
  flex-basis: 0.8%;
}

.ant-custom-checkbox > span:nth-child(2) {
  flex-basis: 99.2%;
  // padding: 0;
}

.ant-custom-checkbox span.ant-checkbox {
  display: flex;
  justify-content: flex-end;
  // margin-left: 8px;
}
.ant-custom-checkbox span.ant-checkbox {
  display: flex;
  justify-content: flex-end;
}

.ant-custom-checkbox .ant-checkbox:hover {
  border-color: transparent !important;
}

.ant-custom-checkbox.ant-checkbox-wrapper-checked > span:nth-child(2) {
  color: #ff671e;
}

.app-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff671e !important;
  border-color: #ff671e !important;
}

.app-checkbox.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #ffb74d !important;
}

.app-checkbox .ant-checkbox-inner {
  border-color: #d9dbe9 !important;
}

.app-checkbox .ant-checkbox-checked::after {
  border-color: #ff671e !important;
}

/*radio*/
.app-radio-group.ant-radio-group {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}
.app-normal-radio-group.ant-radio-group {
  display: flex;
  justify-content: space-between;
}

.app-radio.ant-radio-wrapper {
  display: flex;
  // flex-direction: row-reverse;
  padding: 10px;
  border: 1px solid #d9dbe9;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-right: 0px;
}
.app-radio.ant-radio-wrapper-disabled {
  background: #eff0f1;
  .ant-radio-disabled + span {
    color: #333;
  }
}

.app-radio.ant-radio-wrapper.ant-radio-wrapper-checked {
  background: #ffeacc;
  border-color: #ff671e !important;
}

@media screen and (min-width: 768px) {
  .app-radio.ant-radio-wrapper:hover {
    border-color: #ff671e !important;
  }
}

.app-radio.ant-radio-wrapper > span:nth-child(1) {
  flex-basis: 0.8%;
  display: flex;
  justify-content: flex-end;
}

.app-radio.ant-radio-wrapper > span:nth-child(2) {
  flex-basis: 99.2%;
  font-size: 15px;
}

.app-radio .ant-radio-checked .ant-radio-inner {
  border-color: #ff671e !important;
}

.app-radio .ant-radio-checked .ant-radio-inner::after {
  background-color: #ff671e !important;
}

.app-radio .ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none !important;
}

/*auto complete*/
.app-auto-complete {
  width: 100% !important;
  .ant-select-selection-search {
    input {
      height: 100% !important;
    }
  }
}
.app-auto-complete .ant-select-selector {
  padding: 6px 14px !important;
  border-color: #cacaca !important;
  border-radius: 8px !important;
  height: auto !important;
  @include boxShadow;
}

.app-auto-complete .ant-select-selector:hover {
  border-color: #ffb74d !important;
  @include boxShadow;
}

.ant-select-custom-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #ffeacc !important;
}

.ant-select-custom-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffeacc !important;
}

.ant-select-custom-dropdown {
  border: solid 1px #cacaca !important;
  border-radius: 8px;
  box-shadow: none;
  z-index: 10 !important;
}

.ant-select-custom-dropdown:hover {
  border-color: #ffb74d !important;
  @include boxShadow;
}

.app-auto-complete
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffeacc;
}

.ant-pagination-disabled .ant-pagination-item-link {
  color: #fcfcfc;
  background-color: #d9dbe9;
  opacity: 0.5;
}

.ant-pagination-item {
  border-radius: 4px !important;
  border-color: #d9dbe9;
  height: 40px !important;
  max-width: 40px;
  a {
    font-size: 15px;
    color: #333333 !important;
    font-weight: 400;
    padding: 0 16px !important;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.ant-pagination-item-active a {
  color: #f57c00 !important;
  border-color: #f57c00 !important;
}

.ant-pagination-next,
.ant-pagination-prev {
  height: 40px;
  min-width: 40px;
  border-radius: 4px !important;
  border-color: #d9dbe9;
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border-radius: 4px !important;
  border-color: #d9dbe9;
}

/*override css*/
