.add-screen-container {
  .line-break {
    width: 100%;
    height: 1px;
    background: #d9dbe9;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .media-container {
    margin-top: 24px;
    display: flex;
    .ant-row {
      width: 100%;
    }
  }

  .screen-title {
    margin: 12px 0px;
    color: #333333;
    font-size: 15px;
  }

  .app-custom-checkbox {
    .ant-checkbox-disabled {
      .ant-checkbox-inner {
        border-color: #d9d9d9 !important;
      }
    }
  }

  .screen-note {
    .anticon {
      color: #ffa726;
      padding-right: 4px;
    }
  }
  .checkbox-youtube {
    padding-bottom: 16px;
    padding-top: 24px;
  }
  
  @media screen and (max-width: 991px) {
    .checkbox-youtube {
      padding-bottom: 16px;
      padding-top: 4px;
    }
  }
 

  .note-drag-drop {
    padding-bottom: 12px;
    color: #a3a2a2;
    font-size: 15px;

    .anticon {
      margin-right: 4px;
    }
  }

  .ant-checkbox + span {
    color: #666666 !important;
  }
}

@media screen and (min-width: 992px) {
  .add-screen-container {
    .item-chooser {
      width: max-content !important;
    }
    .note-drag-drop {
      text-align: right;
    }
  }
}
