@import "src/styles/variables.scss";
.ui-notification-screen {

  & > * {
    font-family: "Lato";
  }

  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: none;
  }

  &__items {
    padding: 12px 16px;
    flex-grow: 1;
    height: 1px;
    overflow: auto;
    @media screen and (min-width: 992px) {
      height: 600px;
      overflow: auto;
      //max-height: 100vh;
      width: 750px;
      margin: auto;
      max-height: 100vh;
    }
  }

  .ui-tab {
    display: flex;
    background: #fcfcfc;
    margin: auto;
    border-top: 1px solid #d9dbe9;

    &__pane {
      flex: 1;
      text-align: center;
      padding: 16px 0 16px 2px;
      color: #a3a2a2;
      font-size: 12px;
      cursor: pointer;
      border-bottom: solid 1px #d9dbe9;
      font-weight: bold;

      &.active {
        color: #ff671e;
        border-bottom: solid 1px #ff671e;
      }

      .ant-tag {
        border-radius: 4px;
        padding: 0px 6px;
        margin-right: 0;
        line-height: 18px !important;
      }
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    margin: 0 auto;
  }

  .ant-tabs-tabpane {
    width: 100% !important;
    margin: 0 auto !important;
  }

  .ant-tabs-nav {
    padding-left: 16px;
    padding-right: 16px;
    position: sticky;
    top: 0px;
    z-index: 2;
    height: 44px;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-color: #d9dbe9 !important;
    right: 66px !important;
    left: 66px !important;
  }
  .ant-tabs-tab {
    padding: 12px 42px !important;
  }
  .ant-tabs-tab-btn {
    padding: 4px 8px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $hrsk-primary-color !important;
    border-radius: 8px;
    font-weight: 700;
  }
  .ant-tabs-tab:hover {
    color: #a3a2a2;
  }

  .message-unseen {
    background-color: rgba(202, 202, 202, 0.15) !important;
  }

  .notification-container {
    margin: 0 30px;
    position: relative;
    background: #ffffff;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #d9dbe9;
    box-sizing: border-box;
    margin-top: 16px;
    align-items: center;

    .badge-unseen {
      position: absolute;
      width: 6px;
      height: 6px;
      right: 12px;
      top: 12px;
      background: #ff671e;
      flex: none;
      z-index: 2;
      border-radius: 50%;
    }

    .content-wrapper {
      padding: 10px 6px;
    }

    .notification-date {
      display: flex;
      align-items: center;
      padding-bottom: 0px;
      &__day,
      &__hour {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #666666;
        margin-right: 4px;
      }
    }

    .notification-title {
      margin-top: 0px !important;
    }

    .notification-content {
      margin-top: 4px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      .dot-break {
        margin: 0px 8px;
      }
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
}

@media screen and (min-width: 992px) {
  .ui-notification-screen {
    max-width: 750px;
    margin: auto;
    &__header {
      display: block;
      padding-top: 42px;
      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #333333;
      }
    }

    &__items {
      padding: 24px 12px 0 0;

      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
      }
    }

    .ui-tab {
      &__pane {
        font-size: 14px;
      }
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      right: 86px;
      left: 86px;
    }
    .ant-tabs-tab {
      padding: 12px 130px;
    }
    .ant-tabs-tabpane {
      width: 90%;
    }
  }
}
