.ui-toolbar {
  background: #fff;
  border-bottom: 1px solid #d9dbe9;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;

  .toolbar-body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1334px;
    width: calc(100% - 16px);
    position: relative;

    .image-icon-app {
      height: 58px;
      width: 213px;
      position: absolute;
      left: 0px;
      cursor: pointer;
    }
    .toolbar-title {
      font-size: 24px;
      font-weight: 700;
    }
    .user-name {
      max-width: 100%;
      display: flex;
      align-items: center;
      .three-dot {
        display: inline-block;
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
    .toolbar-notice {
      position: absolute;
      top: -8px;
      right: -5px;
      height: 18px;
      width: 18px;
      color: #ffffff;
      background-color: #F04438;
      font-weight: 700;
      font-size: 11px;
      border-radius: 8px;
      text-align: center;
      display: flex;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 10px;
      span {
        margin: auto;
      }
      &.active {
        color: #FF671E;
        background-color: #ffffff;
      }
    }

    .relative {
      position: relative;
    }

    .h-20 {
      height: 20px;
    }

    .font-9 {
      font-size: 9px !important;
    }
  }

  .toolbar-action-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    .box-action {
      margin-left: 16px;
      padding: 4px 12px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text {
        padding-top: 4px;
      }
    }
    .box-login {
      background: #ff671e;
      color: #fff;
    }
    .box-route.active {
      background: #ff671e;
      color: #fff;
      max-width: 110px;
    }
    .box-route {
      background: #fff;
      max-width: 110px;
      .anticon {
        margin-left: 4px;
      }
    }
    .box-register {
      border: 1px solid #ff671e;
      background: #fff;
      color: #ff671e;
    }
    .box-language {
      background: #eff0f1;
      .text {
        padding-top: 4px;
      }
    }
  }
}

.text-route {
  padding: 4px 12px;
  min-width: 180px;
}

.custom-divider-dropdown {
  margin: 4px 16px !important;
}
