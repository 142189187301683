.app-image-container {
  display: flex;
  align-items: center;
  justify-content: center !important;
  padding: 2px;
  img {
    // width: 100%;
    max-height: 100%;
    height: auto;
    max-width: 100%;
    border-radius: 6px;
  }

  ::selection {
    background: transparent !important;
  }
}
