.ui-contact {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__steps {
    padding: 24px 16px;
  }

  &__render {
    padding: 0 16px;
  }

  &__wrap {
    flex-grow: 1;
    height: 1px;
    overflow: auto;
  }
}

@media screen and (min-width: 992px) {
  .ui-contact {
    display: flex;
    flex-direction: column;
    // align-items: center;
    &__wrap {
      height: 100%;
    }
  }
}
