.ui-sign-up-form {
  &__note {
    padding: 24px 16px 0;
    margin-bottom: 0;
    color: #333333;
    font-size: 15px;
  }

  &__pageTitle {
    position: sticky;
    top: 0px;
    z-index: 1;
  }

  &__explain {
    padding: 0 16px;
    margin-bottom: 0;
    color: #333333;
    font-size: 14px;

    &::before {
      content: "*";
      color: #ff671e;
      margin-right: 4px;
      display: inline-block;
      font-size: 16px;
      line-height: 1;
    }
  }

  &__form {
    padding: 12px 0px 0;

    .ant-form-vertical .ant-form-item-label > label {
      color: #333333;
    }

    .ant-form-item-extra {
      color: #666666;
    }
  }

  &__action {
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 10px 15px;
    border-top: solid 1px #d9dbe9;
    background-color: #ffffff;

    .app-button.ant-btn[disabled] {
      color: #ffffff !important;
    }
  }

  .ant-checkbox + span {
    margin-left: 4px;
  }
}

@media only screen and (max-width: 991px) {
  .ui-sign-up-form {
    .row-mobile {
      margin-left: 0px !important;
      margin-right: 0px !important;
      overflow: hidden;
      .col-s-parent {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .ui-sign-up-form {
    padding-bottom: 30px;
    overflow: hidden;
    &__pageTitle {
      display: none;
    }

    &__note {
      font-size: 16px;
    }

    &__explain {
      font-size: 16px;
    }

    &__form {
      max-width: 587px;
    }

    //.ant-form-item-control .ant-input.app-text-field,
    //.ant-input-password.app-input-password,
    //.app-datepicker.ant-picker {
    //  padding: 6px 14px;
    //}
    //
    //.app-select .ant-select-selector {
    //  padding: 2px 14px !important;
    //}

    &__action {
      position: static;
      border-top: none;
    }
  }
}

.guideline-password {
  color: #666666;
}
