.ui-my-notice {
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #D9DBE9;
  margin-bottom: 16px;
  position: relative;
  background-color: #fff;
  cursor: pointer;

  &.hasUnread {
    background-color: #FCFCFC;
  }

  &__information {
    display: flex;
    align-items: center;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);

    & > img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__title {
    width: 90%;
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;

    @media screen and (max-width: 991px) {
      padding-right: 16px;
    }
  }

  &__sender {
    font-size: 14px;
    color: #666666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media screen and (min-width: 992px) {
      width: 80%;
    }
  }

  &__time {
    color: #666666;
    @media screen and (min-width: 992px) {
      width: 20%;
      text-align: right;
      align-self: end;
    }

    @media screen and (max-width: 991px) {
      min-height: 22px;
    }
  }

  &__unread-quantity {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #FF671E;
    padding: 2px 8px;
    background: rgba(202, 202, 202, 0.5);
    border-radius: 8px 8px 8px 0;
  }

  &__description {
    width: calc(100% - 76px);
    margin-left: 12px;
    word-break: break-all;

    @media screen and (min-width: 992px) {
      & > div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
