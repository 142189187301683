.confirm-contact-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form-confirm-value {
    margin-top: 24px;
  }
  .text-confirm-note {
    padding-bottom: 16px;
    padding-left: 16px;
    font-size: 15px;
  }
  .form-contact {
    padding: 0px 16px;
  }
  .ant-form-item {
    margin-bottom: 8px;
  }
  .button-group {
    margin-top: auto;
    padding: 16px;
    border-top: 1px solid #d9d9d9;
    background: #fff;
    // z-index: 1;
    .ant-form-item {
      padding: 0 !important;
    }
  }
  .buyer-info {
    padding: 0 16px;
    padding-bottom: 16px;
    font-size: 15px;
    word-break: break-all;
    .b-text-info {
      width: 100%;
      word-break: break-word;

    }
  }
}
@media screen and (max-width: 991px) {
  .confirm-contact-form {
    .form-confirm-value {
      flex-grow: 1;
      height: 1px;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }
    .form-contact {
      padding: 0px;
      // flex-grow: 1;
      height: max-content;
      // height: 1px;
      .ant-form {
        display: flex;
        flex-direction: column;
        height: 100%;
        .ant-form-item {
          margin-bottom: 8px;
          padding: 0 16px;
        }
      }
    }
    .button-group {
      margin-top: unset !important;
      padding: 16px;
      border-top: none !important;
      // position: fixed;
      // bottom: 0px;
      // width: 100%;
    }
  }
}

@media screen and (min-width: 992px) {
  .confirm-contact-form {
    .button-group {
      margin-top: unset;
      padding: 16px;
      border-top: none;
      background: #fff;
      z-index: 1;
      max-width: 300px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
