.add-screen-container {
  .post-code {
    border-top: 1px solid #d9dbe9;
    padding-top: 16px;
    margin-top: 16px;
    padding-bottom: 16px;
  }

  .text-break {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
    position: relative;
    top: 7px;
    .br {
      font-size: 1.3px;
      opacity: 0.7;
      width: 12px;
      max-height: 2px;
      background: #333;
    }
  }

  .row-with-des {
    .ant-col-xs-24 {
      margin-bottom: 0px !important;
      .ant-form-item {
        margin-bottom: 0px !important;
      }
    }

    .text-body-4 {
      padding: 0px 8px 12px 8px;
    }
  }

  .text-note {
    font-size: 15px;
    color: #333333;
  }

  .ant-form-item .ant-form-item-label > label {
    color: #333333;
  }

  .text-body-1 {
    color: #333333;
  }

  .ant-form-item-extra {
    font-size: 14px;
    color: #666666;
    margin-top: 8px;
  }
}
