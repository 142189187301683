.custom-popup-select-category {
  z-index: 999 !important;
  padding-top: 0px !important;
  .ant-popover-content {
    width: 720px !important;
    // min-height: 600px;
  }
  .prefecture-form {
    height: 280px;
  }
  .ant-popover-inner-content {
    background: #fff;
    padding: 0px;
    box-shadow: none !important;
  }
  .prefecture-form {
    overflow: auto !important;
    display: block !important;
    .sort-body {
      overflow: hidden !important;
      height: unset !important;
      flex-grow: unset !important;
      padding-top: 4px;
    }
  }

  .form-header {
    padding: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 16px;
    .button-cancel {
      margin-left: auto;
      color: #ff671e;
      cursor: pointer;
    }
  }
}
