.input-number-form-cs {
  .addon-after {
    padding: 0px 0px !important;
    height: 44px;
    display: flex;
    align-items: center;
    .ant-input-number {
      border-right: none;
      padding: 6px 14px !important;
    }
    .ant-input-number-group-addon {
      background: #fff;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .app-input-number {
    input {
      text-align: right;
    }
  }
}
