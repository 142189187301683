.ui-reset-password-result {
  &__content {
    padding-top: 15px;
  }

  &__icon {
    text-align: center;
  }

  &__title {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #333333;
    margin-top: 8px;
  }

  &__description {
    text-align: center;
    font-size: 14px;
    color: #666666;
    max-width: 300px;
    margin: 8px auto 30px;
  }

  &__action {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 10px 15px;
    border-top: solid 1px #D9DBE9;
    background-color: #ffffff;
  }

}

@media only screen and (min-width: 992px) {
  .ui-reset-password-result { 
    margin-top: 80px;
    height: 41vh;

    &__pageTitle {
      display: none;
    }

    &__action {
      position: static;
      border-top: none;
      margin-top: 20px;
    }
  }
}
