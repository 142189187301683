.ui-navigation {
  position: relative;
  // padding: 25px 15px;
  background-color: #FF671E;
  text-align: center;
  position: sticky;
  top: 0px;
  z-index: 990;
  display: flex;
  align-items: center;
  height: 60px;
  min-height: 60px;
  justify-content: center;
  position: relative;

  &__icon {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    text-align: left;
    cursor: pointer;
    position: absolute;
    left: 8px;
    z-index: 1;
  }


  &__title {
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 75px);
  }
}


@media screen and (min-width: 768px) {
  .ui-navigation {
    height: 75px;
    min-height: 75px;
  }
}
