.ui-notification-system-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__view {
    flex-grow: 1;
    height: 1px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__navigation {
    margin-top: 36px;
    cursor: pointer;
    display: flex;
    padding-bottom: 12px;
    span {
      font-weight: 700;
      font-size: 24px;
      color: #333333;
      cursor: text;
    }
  }
  &__line {
    height: 1px;
    width: 100%;
    background-color: #d9dbe9;
    margin-left: 12px;
    margin-right: 12px;
  }
  &__content {
    padding: 14px 16px;

    p {
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: #666666;
      margin-bottom: 18px;
      @media only screen and (min-width: 992px) {
        margin-bottom: 32px;
      }
    }

    &-text {
      word-break: break-all;
      p {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 0;
      }

      &-title {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 4px;
        @media only screen and (min-width: 992px) {
          margin-bottom: 24px;
        }
      }

      .notice-image {
        display: block;
        width: 300px;
        height: auto;
        object-fit: cover;
        margin-top: 10px;
      }

      th,
      td {
        border: 1px solid #333333;
        padding: 2px 4px;
      }
    }
  }
  &__action {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 10px 15px;
    border-top: solid 1px #d9dbe9;
    background-color: #ffffff;
  }
  .img-size {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 992px) {
  .ui-notification-system-detail {
    max-width: 750px;
    margin: auto;
    &__action {
      position: static;
      border-top: none;
      .app-button.ant-btn {
        min-width: 343px;
      }
    }
    &__content {
      padding: 32px 10px 24px;
    }

    &__view {
      height: 100%;
    }
  }
}
