.ui-modal {
  &__icon {
    display: flex;
    justify-content: center;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
    color: #333333;
  }

  &__content {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    text-align: center;
    margin-bottom: 10px;
  }

  &__ok-btn {
    margin-bottom: 10px;
  }

  .app-custom-textarea .ant-form-item-explain-error {
    padding-top: 0 !important;
  }
}
