.ui-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .7);
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  &__loader > img {
    width: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
