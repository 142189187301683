.search-filter-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  .line-break-f {
    width: 100%;
    height: 1px;
    background: #D9DBE9;
    margin-bottom: 12px;
  }

  .ant-form-item-label > label {
    font-size: 16px !important;
  }

  .form-multi {
    .ant-form-item-explain-error {
      min-width: 300px ;
    }
  }
  .form-filter {
    padding: 16px;
    flex-grow: 1;
    height: max-content;
    overflow: auto;
  }

  .button-container {
    border-top: 1px solid #D9DBE9;
    padding: 16px;
    margin-top: auto;
    .button-primary,
    .button-secondary {
      width: 100%;
    }
  }

  .text-break {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    position: relative;
    top: 63px;
    .br {
      font-size: 1.3px;
      opacity: 0.7;
      width: 12px;
      max-height: 2px;
      background: #333;
    }
  }
}


