.search-result-container {
  padding: 0 16px 16px 16px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .search-header-wrap {
    width: 100%;
  }
  .search-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    z-index: 2;
    background: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    &__icon {
      display: flex;
      align-items: center;
      span {
        margin-left: 6px;
      }
    }
  }

  .position-absolute {
    position: absolute;
    top: -1px
  }

  .position-absolute-search-type {
    position: absolute;
    top: -7px
  }

  .header-fixed {
    position: fixed;
    padding-right: 32px;
  }

  .header-fixed-search-type {
    position: fixed;
  }

  .pt-20 {
    padding-top: 20px;
  }

  .pt-32 {
    padding-top: 32px;
  }

  .mr-32 {
    margin-right: 32px;
  }

  .ui-empty {
    padding-top: 36px;
  }

  .disable {
    pointer-events: none;
  }
  .ant-btn {
    margin: 16px 0px;
  }
}

.scroll-hidden {
  overflow: hidden !important;
}