@media only screen and (min-width: 992px) {
  .app-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    .app-body {
      max-width: 100%;
      min-width: 1368px;
      // width: calc(100% - 16px);
      // left: 50%;
      // height: 100%;
      position: relative;
      // transform: translateX(-50%);
      padding-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .outlet {
        width: 1334px;
        min-height: calc(100vh - 95px - 380px);
      }
    }

    .app-toolbar {
      min-width: 1368px;
      height: 95px;
      width: 100%;
      position: sticky;
      top: 0px;
      z-index: 1051;
      background: #fff;
    }

    .app-footer {
      // background: #EFF0F1;
      height: 380px;
      min-width: 1368px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .app-layout {
    height: 100%;
  }
  .app-toolbar {
    display: none;
  }

  .app-body {
    height: 100%;
    .outlet {
      width: 100%;
      height: 100%;
    }
  }

  .app-footer {
    display: none;
  }
}
