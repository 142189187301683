.ui-reset-password-email-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form {
    padding: 24px 0px 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 1px;
    @media only screen and (max-width: 992px) {
      overflow-x: hidden;
      overflow-y: auto;
    }
    z-index: 2;
    .row-input {
      padding: 0 16px;
    }
    .ant-form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 1px;
    }
  }

  &__explain {
    margin-bottom: 12px;
    padding: 0 16px;
    &::before {
      content: "*";
      color: #ff671e;
      margin-right: 4px;
      display: inline-block;
      font-size: 16px;
      line-height: 1;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: auto;
    margin-bottom: 16px;

    background-color: #ffffff;

    &-button {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
      border-top: solid 1px #d9dbe9;
    }
  }
}

@media only screen and (min-width: 992px) {
  .ui-reset-password-email-form {
    padding-bottom: 146px;
    justify-content: center;
    align-items: center;
    max-height: calc(100vh - 492px);
    min-height: 49vh;
    &__form {
      max-width: 33.33333333%;
      width: 100%;
    }
    &__pageTitle {
      display: none;
    }

    &__action {
      position: static;
      //height: 100%;
      margin-top: 0;
      &-button {
        border-top: none;
        padding-top: 8px;
      }
      .ant-col-xl-24 {
        padding-left: 14px !important;
        padding-right: 14px !important;
      }
      .ant-col-xs-24:first-of-type {
        padding-right: 15px !important;
      }
      .ant-col-xs-24:last-of-type {
        padding-left: 15px !important;
      }
    }

    //.ant-form-item-control .ant-input.app-text-field,
    //.ant-input-password.app-input-password,
    //.app-datepicker.ant-picker {
    //  padding: 6px 14px;
    //}
    //
    //.app-select .ant-select-selector {
    //  padding: 2px 14px !important;
    //}
  }
}
