.ui-my-page-favorite {
  height: 100%;
  display: flex;
  flex-direction: column;

  #scrollableDiv {
    padding-top: 20px;
    flex-grow: 1;
    height: 1px;
    overflow: auto;
  }
  &__pagination {
    text-align: center;
  }

  .favorite-skeleton {
    margin: auto;
    &__item {
      padding: 0 16px;
    }
  }
}

.favorite-item {
  padding: 4px 16px;
  @media screen and (min-width: 991px) {
    padding: 24px 0;
  }
  &__wrapper {
    background-color: #fcfcfc;
  }

  &__date {
    font-size: 14px;
    color: #666666;
    margin-bottom: 8px;
  }

  &__product {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px;
    border: solid 1px #d9dbe9;
    border-radius: 8px;
  }

  .disabled-event {
    pointer-events: none;
  }

  &__product-action {
    position: absolute;
    display: block;
    right: 8px;
    top: 10px;
    cursor: pointer;
    z-index: 99;
  }

  &__product-information {
    @media screen and (max-width: 992px) {
      width: 63%;
      height: 117px;
    }
    & > div:nth-child(2) {
      display: flex;
      padding-bottom: 16px;

      & > div:nth-child(2) {
        min-width: 110px;
        &::before {
          content: "";
          padding: 0 12px;
        }
      }
    }
  }

  &__product-image {
    width: 108px;
    height: 117px;
    border-radius: 6px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
    @media screen and (max-width: 992px) {
      max-width: 34%;
      text-align: center;
      margin-right: 8px;
    }
  }

  &__product-image-content {
    height: 100%;
    max-width: 100%;
    border-radius: 8px;
  }

  &__product-name {
    font-size: 14px;
    font-weight: bold;
    padding-right: 26px;
    width: 100%;
  }

  &__product-name {
    padding-bottom: 4px;
  }

  &__product-model,
  &__product-year {
    font-size: 14px;
    font-weight: 700;
    color: #333333;
  }

  &__product-bin {
    display: none;
  }

  &__product-status {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
  }

  &__product-views {
    margin-right: 12px;
  }

  &__product-btn {
    display: none;
  }

  &__product-price {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #ff671e;
    margin-bottom: 4px;
  }

  &__product-price-title {
    margin-right: 8px;
  }
  .three-dot {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .bg-disabled {
    background-color: #eff0f1;
    border-radius: 8px;
  }

  &__product-disabled {
    color: #ff671e;
    font-size: 10px;
    font-weight: 400;
  }

  .pb-8 {
    padding-bottom: 8px !important;
  }
}

@media screen and (min-width: 991px) {
  .favorite-item {
    &__wrapper {
      background-color: #fff;
    }

    &__product {
      flex-direction: column;
      border: none;
      margin: 0 -10px;
      position: relative;
    }

    &__product-action {
      display: none;
    }

    &__product-image {
      object-fit: cover;
      height: 130px;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 2px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
        rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    }

    &__product-bin {
      position: absolute;
      display: block;
      border: none;
      left: 8px;
      top: 8px;
      width: 24px;
      height: 24px;
      background-color: rgba(233, 245, 255, 0.8);
      border-radius: 8px;
      cursor: pointer;
      z-index: 1;
    }

    &__product-information {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 16px;
      padding-top: 4px;
    }

    &__product-name {
      padding-bottom: 8px;
      font-size: 16px;
    }

    &__product-price {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    &__product-price-title {
      margin-right: 8px;
    }

    &__product-status {
      margin-bottom: 12px;
      font-size: 14px;
    }

    .product-btn-disable {
      color: #d9d9d9 !important;
    }

    &__product-model {
      font-size: 16px;
    }

    &__product-year {
      font-size: 14px;
      line-height: 25px;
    }

    &__product-disabled {
      font-size: 12px;
    }
  }
}
