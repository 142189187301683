.ui-contact-message {
  @media screen and (min-width: 992px) {
    padding-bottom: 80px;
  }

  &__note {
    font-size: 15px;

    & > div:nth-child(1) {
      margin-bottom: 24px;
    }

    & > div:nth-child(2) {
      margin-bottom: 12px;
    }
  }

  &__action {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 10px 15px;
    border-top: solid 1px #D9DBE9;
    background-color: #ffffff;
  }
}
