.ui-row {
  display: flex;
  padding: 15px 0;
  height: 100%;

  &__label {
    font-weight: 400;
    font-size: 14px;
    word-break: break-all;
    min-width: 100px;
  }

  &__value {
    font-weight: 700;
    font-size: 14px;
    width: auto;
    word-break: break-all;
    white-space: pre-wrap;
    .box-checked {
      opacity: 0.4;
    }
  }
}

.ui-row.row-checkbox {
  flex-direction: row-reverse;
  align-items: center;
  padding-left: 8px;
  .box-checked {
    height: 18px;
    // padding-right: 8px;
  }

  .ui-row__label {
    padding-left: 8px;
  }
}
