.ui-app-setting-menu {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__content {
        flex-grow: 1;
        height: 1px;
        overflow: auto;
        padding: 0 16px;
        min-width: 360px;
        padding-top: 24px;
        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            padding-bottom: 24px;
        }
        &-left {
            display: flex;
            align-items: center;
            flex-basis: 80%;
        }
        &-label {
            color: #333333;
            font-size: 15px;
            font-weight: 700;
            padding-left: 24px;
        }
        &-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-state {
            margin-right: 14px;
            font-size: 15px;
            font-weight: 400;
            color: #FF671E;
        }
    }
}
