.ui-fieldset {
  &__title {
    font-weight: 700;
    font-size: 17px;
    color: #333333;
    margin-bottom: 10px;
    padding: 16px 0px 0px 16px;
  }

  &__content {
    padding: 10px;
    border: solid 0.5px #D9DBE9;
    border-radius: 8px;
    margin: 16px;

  }

  .ui-row:last-of-type {
    border-bottom: none !important ;
  }
}
