.ui-chat-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 999;
  background-color: #fff;
  overflow: hidden;

  @media screen and (min-width: 992px) {
    width: 375px;
    height: 600px;
    bottom: 0;
    right: 32px;
    top: auto;
    left: auto;
    box-shadow: 0 4px 24px -4px rgba(16, 24, 40, 0.22), 0px 2px 8px -4px rgba(16, 24, 40, 0.03);

    &.hide {
      transform: translateY(550px);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 12px 16px;
    background-color: #FF671E;
    height: 54px;
    max-height: 54px;
    min-height: 54px;
    padding: 0 16px;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__header-title {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
  }

  &__header-actions {
    display: flex;
    align-items: center;
  }

  &__header-hide {
    margin-right: 16px;
    display: flex;
    align-items: center;
  }

  &__header-hide, &__header-close {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__content {
    height: calc(100% - 76px - 84px);
  }

  &__content-body {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100%);

    &.hasFile {
      padding-bottom: 88px;
    }

    @media screen and (min-width: 992px) {
      //height: calc(600px - 54px - 84px);
    }
  }

  &__content-header {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
  }

  &__content-messages {
    padding: 0 16px;
  }

  &__content-header-img {
    margin: 0 auto 8px;

    & > div {
      width: 84px;
      height: 84px;
      border-radius: 8px;
      overflow: hidden;
      display: inline-block;
      text-align: center;
      box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);

      & > img {
        height: 100%;
        width: auto;
      }
    }
  }

  &__content-header-title {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #333;
    word-break: break-all;
  }

  &__actions {
    padding: 0 16px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &__preview {
    padding: 16px 0;
    position: absolute;
    bottom: 100%;
    height: 100px;
    overflow-y: auto;
  }
}
