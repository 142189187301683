.ui-sign-up-complete {
  padding: 70px 16px 0;

  &__logo {
    text-align: center;
  }

  &__logo-title {
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    color: #ff671e;
  }

  &__icon {
    text-align: center;
    margin-top: 60px;
  }

  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
  }

  &__description {
    text-align: center;
    max-width: 310px;
    margin: 0 auto;
    color: #666666;

    & > div {
      color: #ff671e;
      cursor: pointer;
    }
  }
  &__action {
    max-width: 350px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 28px;
    border-bottom: solid 1px #d9dbe9;
    margin-top: 30px;
  }

  &__introduce {
    text-align: center;
    max-width: 350px;
    margin: 48px auto 0;
    color: #333333;
  }

  &__download {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__app-store,
  &__google-play {
    margin-top: 24px;
    cursor: pointer;
  }
  &__google-play {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 992px) {
  .ui-sign-up-complete {
    height: 49vh;
    &__logo,
    &__logo-title,
    &__introduce,
    &__download {
      display: none;
    }

    &__description {
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
      color: #666666;
    }

    &__action {
      border-bottom: none;
    }
  }
}
