@import "src/styles/variables.scss";

.v-list-slider-item {
  display: flex;
  position: relative;
  // background: $hrsk-background-color;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #d9dbe9;
  box-sizing: border-box;
  margin-top: 16px;
  align-items: center;
  .v-child-container {
    flex-grow: 1;
    width: 1px;
    padding: 0px 8px;
    padding-right: 0;
  }
  .button-like {
    left: 16px;
    top: 16px;
  }
  .app-image-container {
    margin-right: 8px;
    width: 132px;
    height: 132px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 6px;
  }


  .product-price {
    // display: flex;
    // align-items: center;
    padding-top: 8px;
    padding-bottom: 0px;

    .action {
      margin-left: auto;
      color: $hrsk-title-color;
      font-size: 14px;
    }
  }
  
  .button-like {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 16px !important;
    top: 16px !important;
    background: rgba(233, 245, 255, 0.8);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .product-title {
    margin-top: 0px !important;
  }
  
  .product-des {
    margin-top: 4px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    .dot-break {
      margin: 0px 8px;
    }
  }
  
  .product-action {
    margin-top: 4px;
    display: flex;
    align-items: center;
    color: #666;
    font-size: 12px;
    .text-value {
      margin-left: 8px;
      margin-right: 16px;
    }
    .action {
      margin-left: auto;
      cursor: pointer;
      color: #333;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .text-action-search {
    margin-left: auto;
    padding: 4px 8px;
    border-radius: 4px;
    float: right;
  }

  .text-year {
    min-width: max-content;
  }
  
}

