// @media screen and (max-width: 991px) {
.ui-steps {
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &__step {
    flex: 1;
    min-width: 130px;
  }

  &__step-label {
    font-size: 12px;
    color: #cacaca;
    margin-bottom: 4px;

    &.active {
      color: #ff671e;
      font-weight: bold;
    }
    &.last {
      text-align: right;
    }
  }

  &__step-content {
    display: flex;
    height: 32px;
  }

  &__step-order {
    user-select: none;
    display: flex;
    align-items: center;
  }

  &__step-order-number {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #cacaca;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cacaca;

    &.done {
      background: transparent;
    }

    &.active {
      width: 32px;
      height: 32px;
      background-color: #ff671e;
      color: #ffffff;
      border-color: #ff671e;
    }
  }

  &__step-tail {
    flex-basis: 90%;
    width: 100%;
    height: 1px;
    background-color: #cacaca;
    margin: auto 15px;
    border-radius: 2px;

    &.active {
      background-color: #ff671e;
    }
  }

  .isContact {
    width: 60px;
    transform: translate(-27px);
  }

  .isContactComplete {
    width: 60px;
    transform: translate(-30px);
  }
}
// }

// @media screen and (min-width: 992px) {
//   .ui-steps {
//     display: flex;
//     justify-content: space-between;
//     overflow-x: scroll;
//     overflow-y: hidden;

//     &::-webkit-scrollbar {
//       width: 0;
//       background: transparent;
//     }

//     &__step {
//       flex: 1;
//       min-width: 130px;
//     }

//     &__step-label {
//       font-size: 14px;
//       color: #cacaca;
//       margin-bottom: 4px;

//       &.active {
//         color: #ff671e;
//         font-weight: bold;
//       }
//     }

//     &__step-content {
//       display: flex;
//     }

//     &__step-order {
//       user-select: none;
//     }

//     &__step-order-number {
//       width: 32px;
//       height: 32px;
//       border-radius: 50%;
//       border: 1px solid #cacaca;
//       color: #cacaca;
//       font-size: 14px;
//       font-weight: 700;
//       display: flex;
//       justify-content: center;
//       align-items: center;

//       &.active {
//         background-color: #ff671e;
//         color: #ffffff;
//         border-color: #ff671e;
//       }
//     }

//     &__step-tail {
//       flex-basis: 90%;
//       width: 100%;
//       height: 1px;
//       background-color: #cacaca;
//       margin: auto 20px;
//       border-radius: 2px;

//       &.active {
//         background-color: #ff671e;
//       }
//     }
//   }
// }
