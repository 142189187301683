.ui-reset-password-collapse {
  padding: 16px;
  border: solid 1px #d9dbe9;
  border-radius: 8px;
  margin-bottom: 8px;

  &__header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
  }

  &__header-text {
    font-size: 14px;
    font-weight: 700;
  }

  &__content {
    font-size: 15px;
  }
}
