@import "src/styles/variables.scss";
.public-form-container {
  position: relative;
  display: flex;
  // height: 100%;
  flex-direction: column;

  .slick-dots {
    bottom: 35px;
  }
  .slick-dots li.slick-active button:before {
    color: #42a5f5;
  }

  .three-dot {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .header-container {
    width: 100%;
    position: relative;
    .button-back {
      position: absolute;
      top: 16px;
      left: 16px;
      color: #fff;
      width: 30px;
      height: 30px;
    }
    .video-container {
      width: 100%;
      // height: 250px;
    }
    .product-info {
      position: absolute;
      bottom: -30px;
      left: 50%;
      max-width: 90%;
      transform: translateX(-50%);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 12px 24px;
      background: $hrsk-background-color;
      box-shadow: 0px 4px 24px -4px rgba(16, 24, 40, 0.08),
        0px 2px 8px -4px rgba(16, 24, 40, 0.03);
      border-radius: 12px;
      border: 0.5px solid;
      border-color: $hrsk-line-color;
      .break-line {
        background: $hrsk-line-color;
        width: 1px;
        height: 25px;
        margin: 0 16px;
      }
      .col-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          text-align: center;
          color: $hrsk-label-color;
          width: max-content;
        }

        .value {
          padding-top: 4px;
          text-align: center;
          color: $hrsk-secondary-color;
          width: max-content;
          width: 80px;
        }
      }
    }
  }

  .product-content {
    // margin-top: 25px;
    padding: 0 16px;
    padding-bottom: 16px;
    .content-info {
      padding-top: 25px;
      .row-price-view {
        display: flex;
        align-items: center;
        margin-top: 12px;
        .price {
          font-size: 20px;
        }
        .view-like-container {
          margin-left: auto;
          .text-value {
            margin-left: 8px;
            margin-right: 16px;
          }
          .text-value:last-of-type {
            margin-right: 0px;
          }
        }
      }
    }

    .content-overview {
      margin-top: 24px;
      .title {
        margin-bottom: 8px;
      }
      .content {
        padding: 14px;
        border: 1px solid $hrsk-line-color;
        border-radius: 8px;
        .row-info:first-of-type {
          padding-top: 0px;
        }
        .row-info:last-of-type {
          border-bottom: none;
          padding-bottom: 0px;
        }
        .row-info {
          display: flex;
          align-items: center;
          padding-bottom: 16px;
          border-bottom: 1px solid $hrsk-line-color;
          padding-top: 16px;
          .i-title {
            min-width: 120px;
            display: flex;
            align-items: center;
          }
          .box-checked {
            // margin-left: auto;
            opacity: 0.4;
            // flex-grow: unset !important;
            height: 18px;
            padding-right: 8px;
          }
          .i-value {
            width: min-content;
            flex-grow: 1;
            overflow: hidden;
            flex-wrap: nowrap;
            word-break: break-all;
            white-space: pre-wrap;
          }
        }
      }
    }
  }

  .button-container {
    bottom: 0px;
    background: #fff;
    z-index: 1;
    border-top: 1px solid $hrsk-line-color;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: center;
    .button-primary {
      width: 50%;
      margin-right: 16px;
    }
    .button-secondary {
      width: 100%;
      // margin-left: 16px;
    }
  }
}

.note-publish {
  padding: 0px 16px 16px 16px;
  .anticon {
    color: #ffa726;
    padding-right: 4px;
  }
}

@media screen and (min-width: 992px) {
  .public-form-container {
    .form-desktop-container {
      display: flex;
      .header-container {
        width: 65%;
      }

      .container-product-content {
        width: 35%;
      }

      .product-content {
        margin-top: 0px;
        padding-right: 0;
        .content-info {
          padding-top: 0px;
        }
      }
    }

    .button-container {
      border-top: none !important;
      margin-top: 0px !important;
      .button-secondary {
        width: 343px !important;
        // margin-left: 16px;
        height: 44px;
      }
    }
  }

  
}
