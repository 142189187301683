.ui-my-page-menu {
  // padding-bottom: 84px;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__content {
    padding: 0 16px;
    min-width: 360px;
  }

  @media screen and (max-width: 991px) {
    &__content {
      flex-grow: 1;
      height: 1px;
      overflow: auto;
    }
  }

  &__name {
    font-size: 20px;
    font-weight: 700;
    color: #ff671e;
    padding-top: 24px;
    padding-bottom: 4px;
  }

  &__email {
    font-size: 14px;
    color: #666666;
    padding-bottom: 24px;
    border-bottom: solid 1px #d9dbe9;
  }

  &__menu {
    padding-top: 24px;
  }

  &__menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-bottom: 24px;
  }
  &__menu-item.hidden {
    display: none;
  }
  &__menu-item.focus {
    .ui-my-page-menu__menu-label {
      color: #ff671e;
    }
  }

  &__menu-left {
    display: flex;
    align-items: center;
    flex-basis: 80%;
  }

  &__menu-label {
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    padding-left: 24px;
  }

  &__arrow {
    flex-basis: 20%;
  }
  .three-dot {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding-right: 30%;
  }

  &__menu-count-notice {
    width: 31px;
    height: 18px;
    background-color: #ff671e;
    color: #ffffff;
    font-size: 11px;
    border-radius: 4px;
    text-align: center;
    margin-left: 10px;
  }
}
