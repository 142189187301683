.item-chooser:first-of-type {
  margin-right: 8px;
}
// .item-chooser:last-of-type {
//   margin-left: 8px;
// }
.item-chooser {
  height: 56px;
  width: 100%;
  border: 1px solid #d9dbe9;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  // justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  &__title {
    padding-left: 8px;
    color: #333333;
  }
}
