.sort-form-bottom-sheet {
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-form {
    padding: 0px 16px;
  }

  .sort-button-container {
    padding: 16px 16px 16px 16px;
    padding-top: 16px;
    border-top: 1px solid #d9dbe9;
    margin-top: auto;
  }
  .sort-radio {
    .app-radio-group.ant-radio-group {
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 16px;
      .ant-row {
        min-width: calc(50vw - 40px);
        max-width: calc(50vw - 40px);
        margin-left: 8px;
        margin-right: 8px;
      }
    }
   
  }
}
