.search-result-container-lg {
  padding: 0 16px 16px 16px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  .search-header {
    display: flex;
    align-items: center;
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 2;
    background: #fff;
    padding-bottom: 16px;
    margin-bottom: 8x;
    border-bottom: 1px solid #d9d9d9;
    &__icon {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-self: flex-end;
      align-self: flex-end;
      .ant-select {
        width: 300px;
        margin-left: 12px;
        .ant-select-selector {
          position: relative;
          height: 44px;
          display: flex;
          align-items: center;
          border-radius: 8px;
          background-color: #fff;
          border: 1px solid #cacaca;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }
      .ant-select-disabled {
        .ant-select-selector {
          background: #f8f8f8 !important;
        }
      }
    }
  }
  .search-body {
    padding-top: 16px;
  }
  .list-slider-item-lg {
    margin-bottom: 24px;
  }
  .result-list {
    border-bottom: 1px solid #d9dbe9;
  }
  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
  }
  .pagination-container.hidden {
    opacity: 0;
  }

  .ant-pagination-options{
    display: none;
  }


}
