.ui-notice-box {
  padding-right: 4px;
  cursor: pointer;
  .message-unseen {
    background-color: rgba(202, 202, 202, 0.15) !important;
  }

  .flex {
    display: flex;
  }

  &__container {
    position: relative;
    background: #ffffff;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #d9dbe9;
    box-sizing: border-box;
    margin-top: 12px;
    align-items: center;

    .badge-unseen {
      position: absolute;
      width: 6px;
      height: 6px;
      right: 12px;
      top: 12px;
      background: #ff671e;
      flex: none;
      z-index: 2;
      border-radius: 50%;
    }

    .count-notice-unseen {
      position: absolute;
      right: 12px;
      top: 20px;
      flex: none;
      z-index: 2;
      font-weight: 700;
      font-size: 14px;
      color: #ff671e;
      background-color: rgba(202, 202, 202, 0.5);
      border-radius: 8px 8px 8px 0px;
    }

    .app-image-container {
      width: 64px;
      height: 64px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
      }
    }

    .content-wrapper {
      padding: 10px 6px;
    }

    .notification-date {
      display: flex;
      align-items: center;
      padding-bottom: 0px;
      &__day,
      &__hour {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #666666;
        margin-right: 4px;
      }
    }

    .notification-title {
      margin-top: 0px !important;
      word-break: break-all;
      padding-right: 4px;
      font-size: 15px;
    }

    .notification-content {
      margin-top: 4px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      .dot-break {
        margin: 0px 8px;
      }
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
}
@media screen and (min-width: 992px) {
  .ui-notice-box {
    &__container {
      .notification-date {
        justify-content: space-between;
      }
    }
  }
}
