@import './antd.css';
/* @import '~antd/dist/antd.css'; */
@import 'src/styles/mixins.scss';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.skiptranslate iframe {
  display: none;
}

#google_translate_element {
  align-items: flex-start;
  flex-direction: row-reverse;
  padding: 8px 18px;
}

#google_translate_element .hide-widget {
  cursor: pointer;
  padding-left: 14px;
}

#goog-gt-tt {
  display: none !important;
}

body {
  top: 0 !important;
}

html {
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

