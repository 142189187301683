.ant-drawer.app-bottom-sheet {
  .ant-drawer-close {
    display: none;
  }
  .ant-drawer-title {
    text-align: center;
  }
  .ant-drawer-content {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-drawer-extra {
    position: absolute;
    right: 16px;
  }
  .ant-drawer-body {
      padding: 0px;
      height: calc(100% - 22px);
  }
}

.app-btn-close {
  padding: 8px 0px;
}