.ui-chat-skeleton {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 60px;
  background: #fff;
  height: 100%;

  &__avatar {
    width: 84px !important;
    height: 84px !important;

    & > span {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__title {
    h3 {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  &__message {
    h3 {
      width: 100% !important;
      height: 70px !important;
    }
  }
}

@media screen and (min-width: 992px) {
  .ui-chat-skeleton {
    width: 375px !important;
    height: 548px !important;
    bottom: 0px !important;
    top: unset !important;
  }
}
