.prefecture-form {
    .sort-all {
        padding: 0 16px;

        @media screen and (min-width: 1024px) {
            padding: 0;
        }

        .ant-row {
            padding-bottom: 0px !important;
        }
    }

    .city-form {
        padding-top: 0px !important;
    }
}
