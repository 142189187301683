@import "src/styles/variables.scss";
.home-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .search-form-container {
    padding: 16px;
    background: $hrsk-primary-color;
  }
  .tab-container.tab-dashboard {
    // .ant-tabs {
    //   overflow-y: auto;
    //   overflow-x: hidden;
    // }

    .ant-tabs-tab-active {
      display: none;
    }
  }
  .tab-container.filter-by-type {
    .ant-tabs-nav {
      display: none;
    }
    .ant-tabs-content-holder {
      height: 100%;
      .ant-tabs-content {
        height: 100%;
      }
    }
  }

  .tab-container.in-app {
    .ant-tabs-content-holder {
      min-height: calc(100vh - 160px);
    }
  }

  .tab-container {
    .ant-tabs-nav-operations {
      display: none !important;
    }
    flex-grow: 1;
    @supports (-webkit-touch-callout: none) {
      height: 1px;
    }
    height: calc(100% - 70px);

    .ant-tabs-content-holder {
      height: calc(100% - 44px);
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 8px;
      padding-bottom: 8px;
      .ant-tabs-content {
        height: 100%;
      }
    }

    .ant-tabs {
      height: 100%;
      display: block;
    }

    .ant-tabs-nav {
      position: sticky;
      top: 0px;
      z-index: 2;
      background: #cacaca;
      height: 44px;
      margin-bottom: 0px;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border-color: $hrsk-line-color;
    }
    .ant-tabs-tab-btn {
      padding: 4px 8px;
      color: #333;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      background: $hrsk-primary-color;
      color: #fff !important;
      margin: 0px 8px;
      border-radius: 8px;
      font-weight: 700;
    }
    .ant-tabs-ink-bar {
      background: $hrsk-primary-color;
      border-radius: 4px;
      display: none;
    }
  }

  .slider-container {
    padding-top: 8px;
    padding-bottom: 24px;

    width: 100%;
    .slider-skeleton-container {
      width: 100%;
      height: 150px;
      padding: 0 16px;
    }
    .slider-item {
      width: 100%;
      height: 150px;
      padding: 0px 16px;
      position: relative;
      img {
        width: 100%;
        height: 150px;
        border-radius: 8px;
        filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
          drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));
      }

      .text-slider {
        position: absolute;
        bottom: 8px;
        height: 40px;
        background: rgba(255, 255, 255, 0.8);
        text-align: center;
        color: $hrsk-title-color;
        width: calc(100% - 64px);
        border-radius: 8px;
        margin-left: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-carousel .slick-dots-bottom {
      bottom: 0px;
    }
    .slick-dots {
      li {
        width: 8px;
        height: 8px;
        button {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: $hrsk-label-color;
          margin-right: 6px;
          margin-left: 6px;
        }
        button::before {
          opacity: 0 !important;
        }
      }

      li.slick-active {
        button {
          background: #ff671e !important;
        }
      }
    }
  }

  .row-caption {
    color: $hrsk-body-color;
  }

  .button-like.disabled {
    pointer-events: none;
  }

  .h-list-container,
  .v-list-container {
    padding: 24px 16px 0px 16px;
    .button-like {
      position: absolute;
      width: 24px;
      height: 24px;
      left: 8px;
      top: 8px;
      background: rgba(233, 245, 255, 0.8);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    .product-title {
      margin-top: 8px;
      width: 100%;
    }

    .product-des {
      margin-top: 4px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      .dot-break {
        margin: 0px 8px;
      }
    }

    .product-price {
      .text-action-search {
        margin-left: auto;
        padding: 4px 8px;
        border-radius: 4px;
        float: right;
      }
    }

    .product-action {
      margin-top: 4px;
      display: flex;
      align-items: center;
      color: #666;
      font-size: 12px;
      .text-value {
        margin-left: 8px;
        margin-right: 16px;
      }
      .action {
        margin-left: auto;
        cursor: pointer;
        color: #333;
        font-weight: bold;
        font-size: 14px;

      }
    }
  }

  .v-list-container {
    .list-slider-item {
      display: flex;
      position: relative;
      background: $hrsk-background-color;
      border-radius: 8px;
      padding: 8px;
      border: 1px solid #d9dbe9;
      box-sizing: border-box;
      margin-top: 16px;
      .button-like {
        left: 16px;
        top: 16px;
      }
      img {
        margin-right: 8px;
        width: 132px;
        height: 132px;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
          0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 6px;
      }
    }

    .product-price {
      // display: flex;
      // align-items: center;
      .action {
        margin-left: auto;
        color: $hrsk-title-color;
        font-size: 14px;
      }
    }
  }

  .h-list-container {
    padding: 24px 16px 0px 16px;
    // .slick-slide {
    //     width: 80vw !important;
    // }
    .list-slider-container {
      width: 100%;
      margin-top: 16px;
      width: 140vw !important;
      .list-container {
        display: flex !important;
        align-items: center;
        .list-slider-item:first-of-type {
          min-width: 70vw !important;
          margin-right: 16px;
        }
      }

      .list-slider-item {
        // width: 80vw !important;
        margin-right: 24px;
        position: relative;

        .app-image-container {
          margin: 1px;
          height: 130px;
          width: 100%;
          border-radius: 8px;
          box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
            0px 1px 2px rgba(16, 24, 40, 0.06);
          // filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
          //   drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));
        }
      }
    }
  }

  .header-list {
    background: #ff671e;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 16px;
    color: #fff;
    .row-1 {
      display: flex;
      align-items: center;
      width: 100%;
      .action {
        margin-left: auto;
        display: flex;
        align-items: center;
        cursor: pointer;
        .anticon {
          justify-content: center;
          font-size: 10px;
          position: relative;
        }
        .action-text {
          margin-right: 4px;
        }
      }
    }
  }

  .img-slider {
    height: 120px;
    padding-right: 12px;
    img {
      height: 120px;
      width: 100%;
      border-radius: 8px;

      // padding-right: 16px;
    }
  }

  .img-slider.banner {
    padding-right: 0px;
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 992px) {
  .home-dashboard-container {
    .desktop-db-view {
      height: 100%;
      display: flex;
      padding-top: 24px;
      .left-db.on-filter {
        min-width: 360px;
        max-width: 360px;
      }
      .left-db {
        width: 260px;
        margin-right: 16px;
        .lg-button-filter {
          cursor: pointer;
          width: 100%;
          height: 52px;
          background: #ff671e;
          border-radius: 8px;
          display: flex;
          align-items: center;
          color: #fff;
          padding: 0 16px;
          svg {
            margin-left: auto;
          }
        }
        .lg-button-category {
          width: 100%;
          height: 52px;
          background: #666666;
          border-radius: 8px;
          display: flex;
          align-items: center;
          color: #fff;
          padding: 0 16px;
          font-weight: 700;
          margin-top: 30px;
          margin-bottom: 12px;
        }

        .lg-button-action {
          margin-top: 12px;
        }
        .child-category:first-of-type {
          margin-top: 0px;
        }
        .child-category {
          margin-top: 12px;
          display: flex;
          cursor: pointer;
          background: #eff0f1;
          border-radius: 8px;
          padding-right: 8px;
          img {
            height: 147px;
            width: 107px;
            border-radius: 6px;
          }
          .left-view-side {
            margin-left: 12px;
            flex-grow: 1;
            width: 100px;
            padding-top: 6px;

            .c-des {
              color: #666666;
              margin-top: 4px;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              overflow: hidden;
              white-space: pre-wrap;
            }
          }
        }
      }
      .right-db {
        margin-left: 16px;
        width: 260px;
        max-height: 1014px;
        overflow-y: auto;
        .img-container:first-of-type {
          margin-top: 0px;
          height: 147px;
          img {
            height: 147px;
          }
        }

        .img-container {
          height: 111px;
          margin-top: 18px;
          cursor: pointer;
        }

        img {
          height: 111px;
          width: 100%;
        }
      }
      .center-db {
        flex-grow: 1;
        width: 1px;

        .lg-list-container {
          width: 100%;
          padding: 42px 16px 0px 16px;

          .ant-row {
            padding-top: 12px;
            width: 100%;
            margin-left: 0px !important;
            margin-right: 0px !important;

            .product-action {
              margin-top: 6px !important;
              font-size: 12px;
            }
            .ant-col {
              padding-left: 16px !important;
              padding-right: 16px !important;
            }

            .ant-col:first-of-type {
              padding-left: 0px !important;
            }
            .ant-col:last-of-type {
              padding-right: 0px !important;
            }

            .list-slider-item {
              position: relative;
            }

            .list-slider-item-lg {
              margin-right: 0px !important;
            }
          }

          .lg-button-action {
            margin-top: 20px;
          }

          .app-image-container {
            margin: 1px;
            height: 115px;
            width: 100%;
            border-radius: 8px;
            box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
              0px 1px 2px rgba(16, 24, 40, 0.06);
            // filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
            //   drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));
          }
        }

        .slick-arrow {
          &::before {
            display: none;
          }
        }

        .button-slider {
          width: 40px;
          height: 40px;
          background: rgba(255, 255, 255, 0.9);
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          border-radius: 50%;
          img.prev {
            position: relative;
            left: 2px;
          }
          img.next {
            position: relative;
            left: -1px;
          }
        }

        .slider-container-desktop {
          padding-top: 1px;
          padding-bottom: 1px;

          width: 100%;
          .slider-skeleton-container {
            width: 100%;
            height: 260px;
            padding: 0 16px;
          }
          .slick-next {
            right: 25px !important;
          }
          .slick-prev {
            left: 25px !important;
            z-index: 1;
          }
          .slick-dots {
            bottom: 25px !important;
          }
          .slick-dots li {
            margin: 0 8px !important;
            width: 6px !important;
            height: 6px !important;
          }
          .slider-item {
            width: 100%;
            height: 260px !important;
            padding: 0px 16px;
            position: relative;
            img {
              width: 100%;
              height: 260px !important;
              border-radius: 8px;
              filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
                drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));
            }

            .text-slider {
              position: absolute;
              bottom: 8px;
              height: 40px;
              background: rgba(255, 255, 255, 0.8);
              text-align: center;
              color: $hrsk-title-color;
              width: calc(100% - 64px);
              border-radius: 8px;
              margin-left: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .ant-carousel .slick-dots-bottom {
            bottom: 0px;
          }
          .slick-dots {
            li {
              width: 8px;
              height: 8px;
              button {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: #cacaca;
                margin-right: 6px;
                margin-left: 6px;
              }
              button::before {
                opacity: 0 !important;
              }
            }

            li.slick-active {
              button {
                background: #ff671e !important;
              }
            }
          }
        }
      }
    }

    .scrollbar-container {
      max-height: 906px;
      overflow-y: auto;
    }
  }
}

.lg-button-action {
  cursor: pointer;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff671e;
  border: 1px solid #ff671e;
  border-radius: 8px;
}
