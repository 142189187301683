.ui-sign-up {
  height: 100%;
  overflow: hidden;
  .ui-sign-up-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 991px) {
      .row-sign-up-mobile {
        flex-grow: 1;
        height: 1px;
        overflow: auto;
        min-height: 300px;
      }
    }
  }
}
