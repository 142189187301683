.ui-chat-previewer {
  width: 100%;
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: #fff;
  max-height: 80px;
  overflow-y: auto;

  &__content {
    padding: 16px;
  }

  &__medias {
    display: flex;
    flex-wrap: wrap;
  }

  &__media {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    margin-right: 16px;
    margin-bottom: 8px;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;

    & > img, video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__media-closer {
    position: absolute;
    right: -15%;
    top: -15%;
    cursor: pointer;
  }

  &__document {
    display: flex;
    position: relative;
  }

  &__document-icon {
    margin-right: 8px;
  }

  &__document-closer {
    position: absolute;
    right: -2%;
    top: -30%;
    cursor: pointer;
  }
}
