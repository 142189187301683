@media screen and (max-width: 991px) {
  .app-modal-full {
    width: 100% !important;
    height: 100%;
    padding: 0px;
    margin: 0px !important;
    max-width: 100% !important;
    .ant-modal-content {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #000;

      .ant-modal-body {
        padding: 0px !important;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 100%;
        width: 100%;

        .kvfysmfp {
          overflow: visible;
        }
      }
    }
    .app-image-container {
      padding: 0px !important;
      max-height: 100%;
      width: 100%;
      img {
        border-radius: 0px;
        width: 100%;
      }
    }
    .app-video-container {
      width: 100%;
    }
  }
}

.close-icon {
  color: #ff671e;
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 1;
  background: rgba(233, 245, 255, 0.8);
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  .anticon {
    font-size: 18px;
  }
}

@media screen and (min-width: 992px) {
  .app-modal-full {
    width: 80% !important;
    height: 80%;
    padding: 0px;
    margin: 0px;
    max-width: 100% !important;
    .ant-modal-content {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #000;
      position: relative;

      .ant-modal-body {
        padding: 0px !important;
        background: #000;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .app-image-container {
      padding: 0px !important;
      height: 100%;
      width: 100%;
      img {
        border-radius: 0px;
      }
    }

    .app-video-container {
      height: 100%;
    }
    .close-icon {
      color: #fff;
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 1;
      // .anticon {
      //   font-size: 20px;
      // }
    }
  }
}
