.prepration-page-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  .content {
    overflow: auto;

    &__description {
      margin-bottom: 16px;
      font-size: 15px;
      font-weight: 400;
    }
    &__info {
      word-break: break-all;
      font-size: 15px;
      &-tag {
        width: 174px;
        height: 32px;
        background: #d1fadf;
        color: #12b76a;
        border-radius: 8px;
        margin-top: 8px;
        align-items: center;
        font-weight: 700;
        display: flex;
        span {
          margin: auto;
        }
      }
    }
    &-view {
      margin: 16px;
      padding-bottom: 64px;
    }
    &__overview {
      .ui-fieldset__content {
        margin: 24px 0;
      }
    }
    &__countdown {
      .timer-wrapper {
        & > div {
          margin: auto;
          border: 4px solid #ff671e;
          border-radius: 50%;
          & > svg {
            display: none;
          }
        }
      }
    }
  }
  .button-container {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 16px 15px;
    border-top: solid 1px #d9dbe9;
    background-color: #ffffff;
  }
}
