.tag-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .tag-add {
    height: 28px;
    border: 1px dashed #ff671e;
    padding: 4px 16px;
    width: max-content;
    border-radius: 8px;
    color: #ff671e;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      margin-right: 8px;
    }
  }

  .tag-value {
    height: 28px;
    display: flex;
    align-items: center;
    padding: 4px 12px;
    width: max-content;
    border-radius: 8px;
    color: #ff671e;
    background: #ffeacc;
    margin-right: 8px;
    margin-bottom: 12px;
    .title {
      margin-right: 16px;
    }
  }
}
