.ui-chat-shower {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 9999;
  padding: 16px;

  &__slider {
    .slick-list {
      .slick-slide {
        & > div {
          height: 96vh;
          display: flex;
          align-items: center;
        }
      }
    }

    .slick-arrow {
      &::before {
        content: "";
      }
    }

    .slick-next {
      right: 0 !important;
    }

    .slick-prev {
      z-index: 9999;
    }
  }

  &__closer {
    position: fixed;
    right: 20px;
    top: 16px;
    z-index: 999999;
    cursor: pointer;
  }

  &__item {
    position: relative;
    max-width: 100%;
    height: 100%;
    margin: auto;
    display: flex !important;
    align-items: center;
    justify-content: center;

    img,
    video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  //&__item-img {
  //  height: max-content !important;
  //}

  &__item-img,
  &__item-video {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;

    & > div {
      position: absolute;
      z-index: 99999;
      top: 0;
      right: 40px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #666;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
