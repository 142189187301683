.datepicker-dropdown-modal {
  .ant-modal-content {
    border-radius: 8px !important;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}
