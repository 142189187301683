.ui-reset-password-success {
  padding-bottom: 70px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    padding: 15px 16px 0;
    height: 1px;
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
  }

  &__icon,
  &__title {
    text-align: center;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
  }

  &__description {
    font-size: 14px;
    text-align: center;
    max-width: 100%;
    margin: 0 auto 48px;
    white-space: pre-wrap;
    color: #666666;
  }

  &__note {
    margin-bottom: 12px;
    text-align: center;
  }

  &__action {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 10px 15px;
    border-top: solid 1px #d9dbe9;
    background-color: #ffffff;
  }
}

@media only screen and (min-width: 992px) {
  .ui-reset-password-success {
    padding-bottom: 30px;

    &__content {
      height: 100%;
    }

    &__description {
      max-width: 65%;
      margin: 0 auto 64px;
    }

    &__action {
      position: static;
      border-top: none;
    }

    .ui-reset-password-collapse {
      padding: 16px;
      border: solid 1px #d9dbe9;
      border-radius: 8px;
      margin-bottom: 8px;

      &__header {
        display: flex;
        justify-content: space-between;
        user-select: none;
        pointer-events: none;
      }

      &__header-icon {
        display: none;
      }

      &__header-text {
        font-size: 14px;
        font-weight: 700;
      }

      &__content {
        font-size: 15px;
      }
    }
  }
}
