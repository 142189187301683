.ui-sign-up-confirmation {
  padding-bottom: 72px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__fieldset {
    padding: 24px 16px 0;
    overflow: auto;
    .ui-fieldset__content {
      margin: 0;
    }
  }

  &__action {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 16px 15px;
    border-top: solid 1px #d9dbe9;
    background-color: #ffffff;
  }
}

@media only screen and (min-width: 992px) {
  .ui-sign-up-confirmation {
    padding-bottom: 60px;

    &__pageTitle {
      display: none;
    }

    &__fieldset {
      padding: 24px 16px 0;

      .ui-fieldset__content {
        border: none;
        .ui-row {
          flex-direction: column;
          padding: 25px 0 6px 0;
        }
      }
      &-form {
        max-width: 607px;
      }
    }

    &__action {
      position: static;
      border-top: none;

      .ant-col-xl-3 {
        padding-left: 4px !important;
        padding-right: 4px !important;
      }

      .app-button.ant-btn {
        padding: 14px 14px;
      }
    }
  }
}
