$hrsk-primary-color: var(--root-primary-color);
$hrsk-primary-color-2: var(--root-primary-color-2);
$hrsk-primary-color-3: var(--root-primary-color-3);
$hrsk-primary-color-4: var(--root-primary-color-4);
$hrsk-primary-color-5: var(--root-primary-color-5);
$hrsk-primary-color-6: var(--root-primary-color-6);

$hrsk-secondary-color: var(--root-secondary-color);
$hrsk-secondary-color-2: var(--root-secondary-color-2);
$hrsk-secondary-color-3: var(--root-secondary-color-3);
$hrsk-secondary-color-4: var(--root-secondary-color-4);
$hrsk-secondary-color-5: var(--root-secondary-color-5);

$hrsk-error-color: var(--root-error-color);
$hrsk-success-color: var(--root-success-color);
$hrsk-title-color: var(--root-title-color);
$hrsk-background-color: var(--root-background-color);
$hrsk-body-color: var(--root-body-color);
$hrsk-line-color: var(--root-line-color);
$hrsk-label-color: var(--root-label-color);
$hrsk-disable-color: var(--root-disable-color);


