.ui-chat-message {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  .fake-video-container {
    position: relative;
    .fake-click {
      position: absolute;
      height: 90%;
      z-index: 1;
      width: 100%;
    }
  }

  &__datetime {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;

    &.isMine {
      text-align: right;
    }
  }

  &__content {
    width: fit-content;
    max-width: 90%;
    padding: 10px 14px;
    font-size: 15px;
    color: #333333;
    background-color: #FCFCFC;
    border: solid 1px #D9DBE9;
    white-space: pre-wrap;
    word-break: break-all;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;

    &.isMine {
      //max-width: 100%;
      font-size: 15px;
      padding: 0;
      color: #333333;
      background-color: #FFFFFF;
      white-space: pre-wrap;
      word-break: break-all;
      border: none;
      display: flex;
      align-items: center;
      align-self: flex-end;

      & > div:nth-child(2) {
        padding: 10px 14px;
        font-size: 15px;
        color: #333333;
        background-color: #EFF0F1;
        white-space: pre-wrap;
        word-break: break-all;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 8px;
      }
    }
  }

  &__document {
    display: flex;
    align-items: center;

    & > span {
      margin-left: 8px;
      color: #FF671E !important;
      text-decoration: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      transition: color 0.3s;
    }
  }

  &__action {
    position: relative;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    cursor: pointer;
  }

  &__medias {
    display: flex;
    flex-wrap: wrap;
  }

  &__media {
    width: 90px;
    height: 90px;
    margin: 8px 9px 8px 0;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img, video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__status {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    text-align: right;
    user-select: none;
  }
}
