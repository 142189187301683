.refresh-container.show {
    height: 40px;
    width: 40px;
    opacity: 1;
    top: -30px;
}
.refresh-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: transparent;
    top: 0px;
    z-index: 999;
    opacity: 0;
    // width: 50px;
    height: 0px;
    width: 0px;
    left: 50%;
    transform: translateX(-50%);

    .icon-bg {
        width: 30px;
        height: 30px;
        background: #fcfcfc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0,0,0,0.08) 0px 4px 12px;

    }
}

