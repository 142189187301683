@import "src/styles/variables.scss";

.confirm-screen-container {
  .media-slider {
    padding-top: 16px;
  }

  @media screen and (min-width: 992px) {
    .media-slider {
      padding-bottom: 16px;
    }
  }

  .product-content {
    margin-top: 0px;
    // padding: 0 16px;
    overflow: auto;
    padding-bottom: 16px;
    .content-info {
      padding-top: 25px;
      .row-price-view {
        display: flex;
        align-items: center;
        margin-top: 12px;
        .view-like-container {
          margin-left: auto;
          .text-value {
            margin-left: 8px;
            margin-right: 16px;
          }
          .text-value:last-of-type {
            margin-right: 0px;
          }
        }
      }
    }

    .content-overview {
      .title {
        margin-bottom: 8px;
      }
      .content {
        padding: 16px;
        border: 1px solid $hrsk-line-color;
        border-radius: 8px;
        .row-info:first-of-type {
          padding-top: 0px;
        }
        .row-info:last-of-type {
          border-bottom: none;
          padding-bottom: 0px;
        }
        .row-info {
          display: flex;
          align-items: center;
          padding-bottom: 16px;
          border-bottom: 1px solid $hrsk-line-color;
          padding-top: 16px;
          .i-title {
            min-width: 130px;
          }
          .box-check {
            margin-left: auto;
            flex-grow: unset !important;
            padding-right: 8px;
          }
          .box-checked {
            opacity: 0.4;
          }
          .i-value {
            width: max-content;
            flex-grow: 1;
            overflow: hidden;
            flex-wrap: nowrap;
            word-break: break-all;
            white-space: pre-wrap;
          }
        }
      }
    }
  }

  .button-container {
    height: 90px;
    background: #fff;
    z-index: 1;
    border-top: 1px solid $hrsk-line-color;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: center;

    .button-primary {
      width: 50%;
      margin-right: 16px;
    }
    .button-secondary {
      width: 50%;
      margin-left: 16px;
    }
  }

  .screen-title {
    color: #333333;
  }
}
// .button-container.confirm-screen-button {
//   button {
//     margin-bottom: 16px;
//   }
// }
@media screen and (min-width: 992px) {
  .confirm-screen-container {
    .product-content {
      margin-top: 0px;
      padding: 0 8px;
      padding-bottom: 16px;
    }
  }
}
