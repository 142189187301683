.ui-modal-confirm-transfer {
  &__icon {
    display: flex;
    justify-content: center;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
    color: #333333;
  }

  &__content {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    text-align: center;
    margin-bottom: 10px;
  }

  &__ok-btn {
    margin-bottom: 10px;
  }
  #scheduledDeliveryDate, #expectedArrivalDate {
    .ant-col:first-of-type {
      padding-right: 8px !important;
    }
    .ant-col:nth-of-type(2) {
      padding-right: 8px !important;
      padding-left: 8px !important;
    }
    .ant-col:last-of-type {
      padding-left: 8px !important;
    }
  }
}
