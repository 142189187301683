.ui-expired-token {
  &__content {
    padding: 15px 16px 0;
  }

  &__icon {
    text-align: center;
  }

  &__title {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #333333;
    margin-top: 8px;
  }

  &__description {
    text-align: center;
    font-size: 14px;
    color: #666666;
    margin: 8px auto 30px;
  }

  &__action {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100vh - 420px);
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 0px 15px;
    background-color: #ffffff;
    margin-top: 64px;
    &-button {
      padding-top: 24px;
      border-top: solid 1px #d9dbe9;
    }
  }

  .isVerified {
    opacity: 0 !important;
    pointer-events: none;
  }
}

@media only screen and (min-width: 992px) {
  .ui-expired-token {
    height: 49vh;

    &__pageTitle {
      display: none;
    }

    &__action {
      position: static;
      display: block;
      width: 100%;
      height: 100%;
      padding: 10px 10px 15px;
      &-button {
        border-top: none;
      }
      margin-top: 20px;
      .ant-col-xl-24 {
        padding-left: 14px !important;
        padding-right: 14px !important;
      }
    }

    .isVerified {
      display: none;
    }
  }
}
