.ui-contact-confirm {
  padding-bottom: 30px;

  &__warning {
    color: #333333;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 12px;
  }

  &__note {
    margin-bottom: 12px;
  }

  &__action {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 10px 15px;
    border-top: solid 1px #D9DBE9;
    background-color: #ffffff;
  }

  .ui-fieldset {
    &__content {
      margin: 16px 0;
    }
  }
}
