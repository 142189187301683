.ui-tag {
  display: inline-flex;
  border-radius: 8px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  width: max-content;

  &__icon {
    margin-right: 7px;
    display: flex;
   
  }

  &__label {
    font-size: 14px;
    font-weight: bold;
  }
}
