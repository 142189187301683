@import "src/styles/variables.scss";

.login-container {
  padding: 16px;
  width: 100%;
  // height: 100vh;
  height: 100%;
  overflow: auto;
  overflow-x: hidden ;
  display: flex;
  flex-direction: column;

  .icon-container {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 137px;
  }

  .login-form {
    .row-login {
      padding-top: 24px;

      .ant-space {
        width: 100%;
      }

      .input-container {
        margin-top: 4px;
      }

      .ant-form-item {
        margin-bottom: 0px;
      }
    }

    .btn-login {
      margin-top: 10px;
    }

    .text-forgot {
      text-align: center;
      margin-top: 16px;
      span {
        cursor: pointer;
      }
    }

    .text-or {
      text-align: center;
      color: #666666;
      margin: 32px 0 12px 0;
    }
    .text-or-unSignUp {
      display: none;
    }
  }

  .text-sign-in {
    text-align: center;
    width: 100%;
    margin-top: 16px;

    & > span:nth-child(2) {
      cursor: pointer;
    }
  }

  .remember-me {
    margin-top: 4px;

    &__text {
      color: #666666;
    }

    .ant-form-item {
      margin-bottom: 4px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .login-container {
    max-width: 392px;
    margin: auto;
    height: max-content;

    .icon-container {
      display: none;
    }

    .ant-form-item-control .ant-input.app-text-field,
    .ant-input-password.app-input-password {
      padding: 6px 14px;
    }

    .remember-me {
      .ant-form-item {
        margin-bottom: 4px;
      }
    }

    .btn-login {
      margin-bottom: 14px;
    }

    .text-forgot {
      margin-top: 0px;
    }

    .login-withoutSignIn {
      display: none;
    }

    .text-sign-in {
      display: none;
    }

    .login-form {
      .row-login {
        .ant-form-item-control-input {
          max-height: 36px;
        }
      }

      .text-or {
        display: none;
      }

      .text-or-unSignUp {
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-align: center;
        color: #333;
        margin: 32px 0;

        &::before {
          content: "";
          background-color: #d9dbe9;
          height: 1px;
          width: 100%;
          margin-right: 1rem;
        }

        &::after {
          content: "";
          background-color: #d9dbe9;
          height: 1px;
          width: 100%;
          margin-left: 1rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .btn-signUp {
    display: none !important;
  }
}
