.ui-quit-membership-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 1px;
    padding-top: 24px;
    @media only screen and (max-width: 992px) {
      overflow-x: hidden;
      overflow-y: auto;
      padding-top: 20px;
    }

    .row-input {
      padding: 0 16px;
    }

    .ant-form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 1px;
    }
  }

  &__note {
    margin-bottom: 0;
    padding: 0 16px;

    white-space: pre-line;
    font-size: 14px;
    color: #666666;
    padding-bottom: 16px;
    &-row {
      display: flex;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: auto;
    margin-bottom: 16px;

    &-button {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
      border-top: solid 1px #d9dbe9;
    }
  }
}

@media only screen and (min-width: 991px) {
  .ui-quit-membership-form {
    &__form {
      .row-input {
        padding: 0;
      }
    }

    &__note {
      padding-left: 0;
      padding-right: 0;
    }

    &__action {
      position: static;
      height: 100%;
      margin-top: 0;
      display: block;

      &-button {
        border-top: none;
        .app-button.ant-btn {
          max-width: 343px;
          margin: auto;
        }
      }
    }
  }
}
