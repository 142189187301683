.ui-modal__content {
  color: #333333 !important;
}
.ant-popover .ant-popover-content {
  width: fit-content;
}
.ui-my-page-sale-history {
  height: 100%;
  display: flex;
  flex-direction: column;
  .wrap-step {
    flex-grow: 1;
    height: max-content;
    display: flex;
    flex-direction: column;
  }

  .ui-tag {
    padding: 3px 14px;
  }

  .ui-my-page-sale-history {
    flex-grow: 1;
    height: max-content;
    display: flex;
    flex-direction: column;
  }
  &__items {
    padding: 0 16px;
    flex-grow: 1;
    height: 1px;
    overflow: auto;
  }

  .row-time {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
  }

  .row-name {
    display: flex;
    width: 100%;
  }

  .ui-tab {
    display: flex;
    background: #fcfcfc;

    &__pane {
      flex: 1;
      text-align: center;
      padding: 16px 0;
      color: #666666;
      font-size: 14px;
      cursor: pointer;
      border-bottom: solid 1px #d9dbe9;

      &.active {
        font-weight: bold;
        color: #ff671e;
        border-bottom: solid 1px #ff671e;
      }
    }
  }

  .sale-ui-history-item {
    position: relative;
    padding-bottom: 24px;
    margin-top: 24px;
    border-bottom: 1px solid #d9dbe9;
    cursor: pointer;

    &__date,
    &__time,
    &__model {
      font-size: 14px;
      color: #666666;
    }

    &__date {
      margin-right: 16px;
    }

    &__name,
    &__model {
      margin-bottom: 24px;
    }

    &__model {
      padding-right: 30%;
    }

    &__name {
      font-weight: bold;
      flex-grow: 1;
      min-width: 50px;
      padding-right: 14px;
      font-size: 15px;
      font-weight: 700;
      &-maker {
        display: flex;
        & > div:nth-child(2) {
          margin-left: 16px;
          min-width: max-content;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-status {
        display: flex;
        flex-direction: column;
        &__tag {
          display: flex;
          flex-direction: column;
          margin-bottom: 14px;
        }
        &__action {
          .sale-ui-history-item-action {
            margin: 8px 0;
            .app-button.ant-btn.ant-btn-custom-secondary {
              color: #333333;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              padding: 4px 12px;
              width: 185px;
              height: 30px;
            }
          }
        }
      }
      &-info {
        flex-grow: 1;
        width: 1px;
      }
    }

    &__price {
      padding-bottom: 8px;
      font-size: 16px;
      .internal-tax {
        display: block;
        font-weight: 400;
        color: #333333;
        font-size: 14px;
      }
      .box-price {
        max-width: 207px;
        display: flex;
        justify-content: space-between;
        &__content {
          .text-caption-2 {
            font-size: 12px;
            line-height: 16px;
          }
          width: max-content;
          display: inline-block;
          margin-left: auto;
        }
      }
      .w-total-price {
        width: 207px;
      }
    }

    &__action {
      float: right;
      width: 30px;
      display: flex;
      justify-content: flex-end;
      padding: 5px 0 10px 12px;
    }
  }

  .ui-my-page-sale-history-detail {
    padding: 24px 16px 0;

    &__timeline {
      margin-top: 8px;
    }

    &__action {
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 10px 10px 15px;
      border-top: solid 1px #d9dbe9;
      background-color: #ffffff;
    }
  }
}

.ui-button-more-sale-history {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 4px 0px;
}

.ui-my-page-purchase-history-detail {
  &__action {
    padding: 16px;
    // position: sticky;
    // bottom: 0;
    background: #fff;
  }

  &__timeline {
    margin-top: 8px;
  }

  &__type-contact {
    border: 1px solid #d9dbe9;
    border-radius: 8px;
    margin: 24px 16px 0;
    padding: 13px;
    font-size: 14px;
    color: #333333;
  }
}

@media screen and (min-width: 992px) {
  .ui-my-page-sale-history {
    height: 100%;

    &__items {
      height: 100%;
      overflow: hidden;
      padding: 0px;
    }

    &__pagination {
      text-align: center;
      margin-top: 24px;
    }
    .sale-ui-history-item__action {
      width: 32px;
      height: 32px;
      div:first-child {
        margin-right: 12px;
      }
    }
    .sale-ui-history-item {
      &__price {
        padding-bottom: 8px;
        font-size: 16px;

        .box-price {
          max-width: 270px;
        }
        .w-total-price {
          width: 270px;
        }
      }
      &__content {
        &-status {
          &__tag {
            flex-direction: row-reverse;
          }
        }
        &-info {
          flex-grow: 1;
          width: 1px;
        }
      }
    }
  }

  .ui-my-page-purchase-history-detail {
    &__action {
      padding: 16px;
      position: relative;
      bottom: 0;
      background: #fff;
      z-index: 2;
    }
  }
}

.ui-my-page-purchase-history-detail {
  .cancel-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: calc(100% - 50px - 16px);
    }
  }
}

@media screen and (min-width: 992px) {
  .ui-my-page-purchase-history-detail {
    .cancel-button {
      button {
        width: 200px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .ui-my-page-purchase-history-detail {
    height: 100%;
    display: flex;
    flex-direction: column;
    .detail-container {
      flex-grow: 1;
      height: 1px;
      overflow: auto;
      width: 100%;
      overflow-x: hidden;
    }
  }
}
