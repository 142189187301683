.selling-page-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  .wrap-step {
    flex-direction: column;
    flex-grow: 1;
    height: 1px;
  }
  .selling-container.done-container {
    padding-left: 0px;
    padding-right: 0px;
    .ui-steps {
      padding-left: 16px;
      padding-right: 16px;
    }
    .app-media-slider {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
  .selling-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    flex-grow: 1;
    overflow: auto;
    overflow-x: hidden;
    .button-container {
      margin-top: auto;
    }
    .ui-steps {
      min-height: 75px;
    }
  }

  .step-done {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 991px) {
  .selling-page-container.step-media {
    .button-container {
      border-top: none !important;
    }
  }
  .selling-page-container {
    .selling-container {
      .public-form-container {
        // flex-grow: 1;
        // height: 1px;
        .button-container {
          .ant-btn {
            margin-left: 16px;
            margin-right: 16px;
            // margin-bottom: 16px;
          }
        }
      }

      .button-container {
        margin-top: auto !important;
        border-top: 1px solid #d9dbe9;
        padding-top: 16px;
        margin-top: 16px;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
        .ant-form-item {
          margin-bottom: 0px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .selling-page-container {
    justify-content: center;
    align-items: center;
    .wrap-step {
      height: auto;
      flex-grow: unset;
    }
    .selling-container.done-container {
      max-width: 100%;
      width: 100%;
    }
    .selling-container {
      height: auto;
      flex-grow: unset;
      max-width: 750px;
      width: 750px;
      .ui-steps {
        max-width: 700px;
        justify-self: center;
        align-self: center;
      }
    }

    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px !important;
      .app-button {
        width: 343px;
      }
    }

    .text-screen-title {
      align-self: flex-start;
      display: flex;
      padding-bottom: 24px;
      padding-top: 8px;
      color: #333333;

      .icon-back {
        margin-right: 32px;
        cursor: pointer;
      }
    }
  }
}
