.ui-network-error {

  &__content {
    width: 100%;
    max-width: 500px;
    padding: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__title {
    font-weight: bold;
    font-size: 22px;
    color: #333;
    text-align: center;
    margin-bottom: 32px;
  }

  &__description {
    font-size: 16px;
  }

  &__action {
    margin-top: 16px;
  }
}
