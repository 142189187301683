.h-skeleton-container {
    padding-top: 16px;
    .h-image-skeleton {
        width: 100% !important;
        height: 130px !important;
        border-radius: 8px !important;
        .ant-skeleton-avatar {
            width: 100% !important;
            height: 100% !important;
            border-radius: 8px !important;
        }
    }
}

.v-skeleton-container {
    display: flex;
    align-items: center;

    .h-image-skeleton {
        width: 132px !important;
        height: 132px !important;
        border-radius: 8px !important;
        margin-right: 8px;
        .ant-skeleton-avatar {
            width: 132px !important;
            height: 132px !important;
            border-radius: 8px !important;
        }
    }
}

.category-skeleton-container {
    display: flex;
    align-items: center;

    .category-image-skeleton {
        width: 110px !important;
        height: 140px !important;
        border-radius: 8px !important;
        margin-right: 8px;
        .ant-skeleton-avatar {
            width: 110px !important;
            height: 140px !important;
            border-radius: 8px !important;
        }
    }
}

.slider-skeleton-container {
    .slider-image-skeleton {
        width: 100%;
        height: 100%;
        .ant-skeleton-avatar {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }
    }
}

.app-favorite-skeleton-item {
    width: 100%;
    height: 100%;
    padding: 0 16px;

    @media screen and (max-width: 1200px){
        display: flex;
    }

    .app-favorite-skeleton-img {
        width: 100%;
        height: 100%;
        @media screen and (max-width: 1200px){
            width: 114px;
            height: 114px;
            margin-right: 12px;
        }
    }
}

.app-favorite-skeleton-date {
    width: 100%;
    height: 100%;
    padding-left: -16px;
}


.product-skeleton-button {
    width: 100% !important;
  }
  
  .product-skeleton-avatar {
    width: 100% !important;
    height: 560px;
  
    span {
      width: 100% !important;
      height: 100% !important;
    }
  
    @media screen and (max-width: 992px) {
      height: 250px !important;
    }
  }
  
  .product-skeleton-slider {
    width: 100% !important;
    height: 80px;
  
    span {
      width: 100% !important;
      height: 100% !important;
    }
  }
  
  
  .skeleton-product {
    padding: 8px;
    .ant-skeleton-avatar {
      border-radius: 4px;
    }
  }