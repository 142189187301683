@import 'src/styles/variables.scss';

.search-form-container {
    display: flex;
    align-items: center;
    min-height: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    .input-container {
        height: 40px;
        border-radius: 8px;
        border-color: $hrsk-label-color;
        input::placeholder {
            color: $hrsk-label-color;
        }
    }

    .ant-input-prefix {
        margin-right: 8px !important;
    }

    .icon-filter {
        margin-left: 12px;
        font-size: 12px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .text {
            width: max-content;
        }
    }
    .icon-back {
        margin-right: 12px;
    }
}

@media screen and (min-width: 768px ) {
    .search-form-container {
        display: flex;
        align-items: center;
        min-height: 75px;
        height: 75px;
  }
}

@media screen and (min-width: 992px) {
    .search-form-container {
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .input-container {
            height: 44px;
            border-radius: 4px;
            width: 350px;
            background: #f8f8f8;
            border-color: transparent;
            border: none;
            display: flex;
            flex-direction: row-reverse;
            margin-right: 167px;
            input::placeholder {
                color: $hrsk-label-color;
            }
            .ant-input {
                background: #f8f8f8;
            }
        }
    
        .ant-input-prefix {
            margin-right: 8px !important;
        }
    
        .icon-filter {
            display: none;
          
        }
        .icon-back {
            display: none;
        }
    }
}