@import "src/styles/variables.scss";

.product-detail-container {
  .row-price-view {
    & > div:first-child {
      font-size: 20px !important;
    }
  }

  position: relative;
  display: flex;
  padding-bottom: 72px;
  flex-direction: column;
  height: 100%;

  .product-maker {
    word-break: break-all;
  }

  .product-title {
    // flex-basis: 90%;
    flex-grow: 1;
    width: 1px;
    word-break: break-all;
    @media screen and (min-width: 992px) {
      // flex-basis: 80%;
      flex-grow: 1;
      width: 1px;
    }
  }

  .header-container {
    width: 100%;
    position: relative;

    .button-back {
      position: absolute;
      top: 16px;
      left: 16px;
      color: #fff;
      z-index: 1;

      position: absolute;
      color: #fff;
      z-index: 1;
      background: rgba(233, 245, 255, 0.8);
      border-radius: 8px;
      width: 32px;
      height: 32px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ff671e;
    }

    .button-like {
      position: absolute;
      top: 15px;
      right: 16px;
      color: #fff;
      z-index: 1;
      font-size: 20px;

      position: absolute;
      color: #fff;
      z-index: 1;
      background: rgba(233, 245, 255, 0.8);
      border-radius: 8px;
      width: 32px;
      height: 32px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ff671e;
    }

    .button-like.active {
      color: #ff671e;
    }

    .button-like.disalbed {
      pointer-events: none;
    }

    .video-container {
      width: 100%;
      height: 250px;
    }

    .product-info {
      position: absolute;
      bottom: -30px;
      left: 50%;
      max-width: 90%;
      transform: translateX(-50%);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 12px 24px;
      background: $hrsk-background-color;
      box-shadow: 0px 4px 24px -4px rgba(16, 24, 40, 0.08),
        0px 2px 8px -4px rgba(16, 24, 40, 0.03);
      border-radius: 12px;
      border: 0.5px solid;
      border-color: $hrsk-line-color;

      .break-line {
        background: $hrsk-line-color;
        width: 1px;
        height: 25px;
        margin: 0 16px;
      }

      .col-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          text-align: center;
          color: $hrsk-label-color;
          width: max-content;
        }

        .value {
          padding-top: 4px;
          text-align: center;
          color: $hrsk-secondary-color;
          width: max-content;
          width: 80px;
        }
      }
    }
  }

  .product-content {
    margin-top: 16px;
    padding: 0 16px;
    // flex-grow: 1;
    // height: calc(100vh - 348px - 100px);
    // overflow: auto;
    padding-bottom: 80px;

    .content-info {
      padding-top: 0px;

      .row-price-view {
        display: flex;
        align-items: center;
        margin-top: 12px;

        .view-like-container {
          margin-left: auto;

          .text-value {
            margin-left: 8px;
            margin-right: 16px;
          }

          .text-value:last-of-type {
            margin-right: 0px;
          }
        }
      }
    }

    .content-overview {
      margin-top: 24px;
      .ui-fieldset__title {
        padding: 0px;
      }
      .ui-fieldset__content {
        margin: 0px;
      }

      .title {
        margin-bottom: 8px;
      }

      .content {
        padding: 16px;
        border: 1px solid $hrsk-line-color;
        border-radius: 8px;

        .row-info:first-of-type {
          padding-top: 0px;
        }

        .row-info:last-of-type {
          border-bottom: none;
          padding-bottom: 0px;
        }

        .row-info {
          display: flex;
          align-items: center;
          padding-bottom: 16px;
          border-bottom: 1px solid $hrsk-line-color;
          padding-top: 16px;

          .i-title {
            min-width: 90px;
          }
        }
      }
    }
  }

  .button-container {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 16px 16px 15px;
    border-top: solid 1px #d9dbe9;
    background-color: #ffffff;
  }
}

.ui-product-detail-desktop {
  margin-top: 32px;

  img.desktop-selected-img {
    width: 100%;
    height: 590px;
    border-radius: 8px;
    cursor: pointer;
  }

  .slick-slider {
    .slick-slider-arrow {
      position: absolute;
      top: 50%;
      z-index: 10;
      cursor: pointer;

      &.left {
        left: 16px;
      }

      &.right {
        right: 16px;
      }
    }
  }
}

.slick-slide {
  .product-img-slider {
    padding: 10px;
    cursor: pointer;

    img {
      // height: 82px;

      @media screen and (min-width: 992px) {
        height: 160px;
      }
    }

    &.selected {
      border: solid 2px #ff671e;
      border-radius: 8px;
    }
  }
}

.product-detail-slider-mobile {
  .slick-arrow {
    width: auto;
    height: auto;

    &::before {
      content: none;
    }

    &.slick-next {
      right: -30px;
    }

    &.slick-prev {
      left: -30px;
    }
  }
}

.slick-disabled {
  opacity: 0;
  pointer-events: none;
}

@media screen and (min-width: 992px) {
  .product-detail-container {
    .button-like {
      position: absolute;
      top: 10px;
      right: 32px;
      color: #fff;
      z-index: 1;
      background: rgba(233, 245, 255, 0.8);
      border-radius: 8px;
      width: 32px;
      height: 32px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ff671e;
    }

    .button-like.active {
      color: #ff671e;
    }

    .button-like.disalbed {
      pointer-events: none;
    }
  }
}
@media screen and (max-width: 767) {
  .product-detail-container {
    .app-media-slider {
      padding-left: 37px;
      padding-right: 37px;
    }
  }
}
@media screen and (max-width: 991) and (min-width: 768) {
  .product-detail-container {
    .app-media-slider {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.product-skeleton-button {
  width: 100% !important;
}

.product-skeleton-avatar {
  width: 100% !important;
  height: 560px;

  span {
    width: 100% !important;
    height: 100% !important;
  }

  @media screen and (max-width: 992px) {
    height: 250px !important;
  }
}

.product-skeleton-slider {
  width: 100% !important;
  height: 80px;

  span {
    width: 100% !important;
    height: 100% !important;
  }
}


.skeleton-product {
  padding: 8px;
  .ant-skeleton-avatar {
    border-radius: 4px;
  }
}
