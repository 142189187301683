.ui-my-page-profile-view {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (min-width: 992px) {
    height: 100%;
  }

  .ui-row {
    &__label {
      color: #666666 !important;
    }
  }

  &__id {
    text-align: center;
    padding: 10px 0;
    border-radius: 8px;
    border: solid 1px #ff671e !important;
    font-size: 15px;
    font-weight: bold;
    margin: 24px 15px;
  }

  &__action {
    border-top: solid 1px #d9dbe9;
    padding: 16px;
    @media screen and (min-width: 992px) {
      border-top: none;
      padding-top: 24px;
      .app-button.ant-btn.ant-btn-custom-primary {
        max-width: 343px;
      }
    }
    .ant-row {
      .ant-col {
        padding-bottom: 0px !important;
      }
    }
  }

  &__overview {
    .ui-fieldset__title {
      font-size: 14px;
    }
  }
}

.ui-my-page-profile {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ui-my-page-profile-edit {
    flex-grow: 1;
    height: 1px;
    overflow: auto;
    @media screen and (min-width: 992px) {
      padding: 0;
      height: 100%;
    }

    &__note {
      font-size: 15px;
      padding: 24px 16px 12px;
      color: #333333;

      &::before {
        content: "*";
        color: #ff671e;
        margin-right: 5px;
        font-weight: bold;
        font-size: 16px;
      }
    }

    &__form {
      overflow: hidden;
      .ant-input[disabled] {
        color: #000000b3;
      }

      .form-email {
        margin-bottom: 16px;

        &__label {
          color: #333333;
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 6px;

          &::after {
            content: "*";
            color: #ff671e;
            margin-left: 4px;
            display: inline-block;
            font-size: 17px;
            line-height: 1;
            margin-top: 4px;
            position: absolute;
          }
        }

        &__content {
          color: #ff671e;
          font-weight: 700;
          font-size: 14px;
        }
      }
      &-input {
        padding: 0 16px;
      }
    }

    &__action {
      position: static;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 16px;
      border-top: solid 1px #d9dbe9;
      background-color: #ffffff;
      @media screen and (min-width: 992px) {
        border-top: none;
        padding-top: 0px;
        .app-button.ant-btn {
          max-width: 343px;
        }
      }
    }
    .ant-form-item-extra,
    .ant-input {
      color: #666666;
    }
  }
}

.ui-my-page-profile-desktop {
  padding-top: 24px;
  padding-bottom: 21px;
  border-bottom: solid 1px #d9dbe9;
  color: #333;

  & > div:first-child {
    font-size: 24px;
    font-weight: bold;
  }

  & > div:last-child {
    font-size: 15px;
    font-weight: bold;
  }
}
