.app-category-group {
  width: 100%;
  .tree-container {
    padding-bottom: 8px;
    width: 100%;
    .tree-child {
      padding-left: 32px;
      padding-top: 8px;
      .ant-custom-checkbox {
        color: #666;
      }
    }

    .ant-radio-wrapper {
      display: flex;
      // flex-direction: row-reverse;
      span:last-of-type {
        margin-right: auto;
      }
    }
    .tree-normal {
      margin-left: 14px;
     
    }

    .tree-last {
      .ant-radio-wrapper {
        color: #666;
        span {
          color: #666;
        }
      }
    }

    .tree-parent {
      width: 100%;
      display: flex;
      align-items: center;
      .tree-icon {
        margin-right: 8px;
      }
      .ant-custom-checkbox {
        margin-left: 12px;
      }
      label {
        width: 100%;
      }
    }

    .ant-checkbox-disabled + span {
      color: #333 !important;
    }
  }
}
