.ui-my-page-bank-view {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (min-width: 992px) {
    height: 100%;
  }

  &__wrap {
    padding-bottom: 68px;
    @media screen and (min-width: 992px) {
      padding-bottom: 0px;
    }
  }

  .ui-row {
    &__label {
      color: #666666 !important;
    }
  }

  &__id {
    text-align: center;
    padding: 10px 0;
    border-radius: 8px;
    border: solid 1px #ff671e !important;
    font-size: 15px;
    font-weight: bold;
    margin: 24px 15px;
  }

  &__guideline {
    margin: 0 15px;
    @media screen and (min-width: 992px) {
      padding-top: 24px;
      margin: 0;
    }
    color: #ff671e;
    display: flex;
    align-items: flex-start;
    > span:nth-child(1) {
      margin-right: 9px;
      margin-top: 4px;
    }

    > span:nth-child(2) {
      max-height: max-content;
      margin-bottom: 16px;
    }
  }

  &__action {
    padding: 10px 0px 15px;
    border-top: solid 1px #d9dbe9;
    padding: 16px;
    @media screen and (min-width: 992px) {
      border-top: none;
      padding-top: 24px;
      .app-button.ant-btn.ant-btn-custom-primary {
        max-width: 343px;
      }
    }
  }

  &__overview {
    .ui-fieldset__content {
      margin-top: 0px;
    }
  }
}

.ui-my-page-bank {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ui-my-page-bank-edit {
    flex-grow: 1;
    height: 1px;
    overflow: auto;
    @media screen and (min-width: 992px) {
      padding: 0;
      height: 100%;
    }

    &__guideline {
      color: #ff671e;
      display: flex;
      align-items: flex-start;
      padding: 24px 16px 16px 16px;
      > span:nth-child(1) {
        margin-right: 9px;
        margin-top: 4px;
      }
    }

    &__guideline-name {
      color: #666666;
      margin-top: -5px;
      margin-bottom: 10px;
    }

    &__form {
      overflow: hidden;
      .ant-input[disabled] {
        color: #000000b3;
      }
      &-input {
        padding: 0 16px;

        .ant-select-clear {
          right: 40px;
        }
      }
    }

    &__action {
      border-top: solid 1px #d9dbe9;
      padding: 16px;
      @media screen and (min-width: 992px) {
        border-top: none;
        padding-top: 24px;
        .app-button.ant-btn.ant-btn-custom-primary {
          max-width: 343px;
        }
      }
    }

    .ant-form-item-extra,
    .ant-input {
      color: #666666;
    }

    .ant-radio-wrapper {
      & > span:nth-child(2) {
        font-weight: 700;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .ui-my-page-bank {
    display: flex;
    flex-direction: column;
    .ui-my-page-bank-view {
      flex-grow: 1;
      height: 1px;
      .row-parent {
        height: 100%;
      }
      .col-parent {
        display: flex;
        flex-direction: column;
      }
      .ui-my-page-bank-view__action {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #fff;
      }
    }
  }
}
