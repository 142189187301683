.ui-chat-sender {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;

  &__border {
    margin: 0 -16px;
    border-top: 1px solid #D9DBE9;
  }

  &__attachments {
    display: flex;
    flex-basis: 10%;

    @media screen and (max-width: 991px) {
      flex-basis: 20%;
    }
  }

  &__file {
    margin-right: 8px;
  }

  &__image {
    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  &__input {
    position: relative;
    flex-basis: 75%;

    @media screen and (max-width: 992px) {
      flex-basis: 65%;
    }

    textarea {
      padding: 10px 40px 10px 14px;
      border: solid 1px #CACACA;
      border-radius: 8px;
      outline: none;
      resize: none;
      overflow: hidden;
      width: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }

  &__send {
    flex-basis: 15%;
    text-align: right;

    & > svg {
      cursor: pointer;
    }
  }

  &__emoji-icon {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);

    & > svg {
      cursor: pointer;
    }
  }

  &__emoji-picker {
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
  }
}
