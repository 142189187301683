.ui-chat-uploader {
  input {
    display: none;
  }

  &__icon {
    & > svg {
      cursor: pointer;
    }
  }
}
