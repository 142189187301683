.prefecture-form {
  .sort-body {
    .ant-radio-group {
      flex-direction: row !important;
      .ant-row:first-of-type {
        margin-right: 36px;
      }
      .ant-row {
        flex-basis: 50%;
        padding-bottom: 0px !important;
      }
    }
  }

  &.multi-select {
    .sort-body {
      .ant-radio-group {
        flex-direction: row !important;
        justify-content: space-between;
        .ant-row:first-of-type {
          margin-right: 0;
        }
        .ant-row {
          flex-basis: 30% !important;
          padding-bottom: 0px !important;
        }
      }
    }
  }
}
