.search-filter-form-lg {
  display: flex;
  flex-direction: column;
  .ant-form-item-label > label {
    font-size: 17px !important;
  }
  // height: calc(100vh - 110px);
  .form-filter {
    .form-multi.first {
      padding-top: 16px;
    }
    .form-multi {
      .ant-form-item-explain-error {
        min-width: 300px;
      }
      .ant-col-xs-24 {
        padding-right: 8px !important;
      }
      .ant-col-xs-2 {
        padding-right: 16px !important;
      }
      .ant-col-xs-11 {
        padding-right: 0px !important;
      }
    }
    #prefecture {
      .col-prefecture {
        padding-right: 16px !important;
      }
    }
  }

  .prefecture-form {
    .sort-all {
      position: sticky;
      top: 0px;
      background: #fff;
      z-index: 10;
    }
  }
  .prefecture-form .sort-body {
    height: 100%;
    padding: 0px !important;
  }
  .filter-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d9dbe9;
    padding-bottom: 16px;
    .filter-action {
      display: flex;
      align-items: center;
      margin-left: auto;
      .app-button {
        margin-left: 12px;
        width: 100px;
      }
    }
  }
  .row-filter {
    padding: 16px 0px;
    border-bottom: 1px solid #d9dbe9;
  }
  .row-filter:last-of-type {
    border-bottom: none;
  }
  .prefecture-form .ant-row {
    padding-bottom: 0px;

    @media screen and (min-width: 1024px) {
      padding-bottom: 12px !important;
    }
  }
  .prefecture-form {
    padding: 16px 16px 16px 16px;
    border-bottom: 1px solid #d9dbe9;
    padding-left: 0px;
  }
  .form-filter {
    padding: 16px;
    padding-top: 0px;
    flex-grow: 1;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    .ant-row {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .ant-col {
      padding-left: 0px !important;
      padding-right: 8px !important;
    }
    // overflow: auto;
  }

  .button-container {
    border-top: 1px solid #d9dbe9;
    padding: 16px;
    margin-top: auto;
    .button-primary,
    .button-secondary {
      width: 100%;
    }
  }

  .fake-select:hover,
  .fake-select:focus {
    border: 1px solid #ffb74d;
    svg {
      color: #ffb74d;
    }
  }

  .fake-select.focus {
    border: 1px solid #ffb74d;
    svg {
      color: #ffb74d;
    }
  }

  .fake-select {
    background: #ffffff;
    height: 44px;
    border: 1px solid #cacaca;
    width: 100%;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    margin: 12px 0px;
    svg {
      margin-left: auto;
      font-size: 10px;
    }
  }

  .text-break {
    width: 100%;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
    position: relative;
    top: 63px;
    .br {
      font-size: 1.3px;
      opacity: 0.7;
      width: 12px;
      max-height: 2px;
      background: #333;
    }
  }
  .ant-form-item {
    margin-bottom: 12px;
  }
  .text-title {
    line-height: 32px;
  }
  .tag-add {
    display: none;
  }
}

.custom-popup-select-province {
  padding-top: 0px !important;

  .ant-popover-content {
    width: 344px !important;
  }
  .prefecture-form {
    height: 272px;
    .ant-col {
      padding-right: 0px !important;
    }
  }
  .ant-popover-inner-content {
    background: #fff;
    padding: 0px;
    box-shadow: none !important;
  }
  .prefecture-form .sort-all {
    padding: 0 16px !important;
  
    .ant-row {
      padding-bottom: 0px !important;
    }
  }

  .prefecture-form {
    overflow: auto !important;
    display: block !important;
    padding: 0px !important;
    .sort-all {
      position: sticky;
      top: 0px;
      z-index: 10;
      background: #fff;
    }
    .sort-body {
      overflow: hidden !important;
      height: unset !important;
      flex-grow: unset !important;
      padding-top: 4px;
    }

    .ant-form {
      padding: 6px 16px 0 !important;
    }
  }
}
