.add-screen-container {
  .col-note {
    position: relative;
    top: -24px;
    font-size: 12px;
  }

  .ant-form-item {
    margin-bottom: 12px !important;
  }
  .ant-checkbox + span {
    font-size: 15px;
    color: #333333;
  }
}

.line-break-d {
  width: 100%;
  height: 1px;
  background: #d9dbe9;
  margin-bottom: 16px;
  margin-top: 8px;
}

@media screen and (min-width: 992px) {
  .col-checkbox {
    width: max-content;
    flex: unset;
  }
}
