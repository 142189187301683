.my-page-layout {
  width: 100%;
  display: flex;
  // align-items: center;
  .left-view {
    width: 360px;
  }

  .right-view {
    flex-grow: 1;
    min-width: 100px;
  }
}
