.prefecture-form {
  .sort-body {
    .ant-radio-group {
      flex-direction: row !important;
      .ant-row:first-of-type {
        margin-right: 36px;
      }
      .ant-row {
        flex-basis: 50%;
        padding-bottom: 0px !important;
      }
      .ant-radio-wrapper-checked {
        span {
        color: #FF671E;
        }
      }
    }
  }
}


@media screen and (min-width: 992px) {
    .prefecture-form.category-form {
      height: 320px !important;
    }
}